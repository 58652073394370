import { create } from 'zustand'
import IManagedState from './IManagedState'
import * as apiDirectory from '../services/apiDirectory'
import * as xtypes from 'xtypes'

export interface ProfileState extends IManagedState {
    principal?: xtypes.directory.IPrincipal;
}

export const useProfileStore = create<ProfileState>((set) => ({
    didInit: false,
    error: undefined,
    clearError: () => set({ error: undefined }),
    init: async () => {
        try {
            const principal = await apiDirectory.getSelf();
            // const principalPromise = apiDirectory.getSelf();
            // const [principal] = await Promise.all([principalPromise])
            set({ didInit: true, principal });
        } catch (err: any) {
            set({ error: err });
        }
    },
}));

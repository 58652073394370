import log from 'loglevel';
import * as api from './apiActions'
import * as xtypes from 'xtypes'

export async function getPositions(): Promise<xtypes.site.IPosition[]> {
    try {
        const response = await api.httpGet('GET POSITIONS', api.url('api/us/positions'), true);
        return await api.json<xtypes.site.IPosition[]>(response);
    } catch (err) {
        log.error(err);
        return [];
    }
}

import { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import Backdrop from '../../components/general/Backdrop'
import * as api from '../../services/apiDirectory'
import styles from './UserInvite.module.css'

interface IInviteValidation {
    email?: string;
    user?: string;
    display?: string;
}

const lengthUser = 6;
const patternEmail = new RegExp('^([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$');
const patternUser = new RegExp('^([A-Za-z0-9_-]+)$');

export default function UserInvite() {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [didInit, setInit] = useState(false);
    const [isProcessing, setProcessing] = useState(false);
    const [isValidated, setValidated] = useState(false);
    const [validation, setValidation] = useState<IInviteValidation>({});
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userDisplay, setUserDisplay] = useState('');

    useEffect(() => {
        if (!didInit) {
            setInit(true);
            inputRef.current?.focus();
        }
    }, [didInit])

    function onReset() {
        setUserName('');
        setUserEmail('');
        setUserDisplay('');
        setValidated(false);
        setValidation({});
        inputRef.current?.focus();
    }

    async function onInvite() {
        const validation = validateForm();
        setValidated(true);
        if (validation == null) {
            return;
        }

        setProcessing(true);
        const response = await api.inviteUser(validation.email!, validation.user!, validation.display!);
        setProcessing(false);
        if (response != null) {
            // SUCCESS
            console.log('SUCCESS');
            onReset();
        } else {
            // FAILURE
            console.log('FAILURE');
        }
    }

    function validateForm(): IInviteValidation | undefined {
        let email = requireMatch(userEmail.toLowerCase(), patternEmail);
        let user = requireMatch(userName, patternUser);
        user = requireLength(user, lengthUser);
        let display = requireInput(userDisplay);

        const validation: IInviteValidation = {
            email,
            user,
            display
        };
        setValidation(validation)

        if ((validation.email != null) &&
            (validation.user != null) &&
            (validation.display != null)) {
            return validation;
        }
    }

    function isFormComplete(): boolean {
        return (
            userName.trim().length > 0 &&
            userEmail.trim().length > 0 &&
            userDisplay.trim().length > 0
        );
    }

    function requireMatch(input: string, pattern: RegExp): string | undefined {
        const text = requireInput(input);
        if (text == null) {
            return;
        }
        if (text.match(pattern)) {
            return text;
        }
    }

    function requireInput(input: string): string | undefined {
        const clean = input.trim();
        if (clean.length === 0) {
            return;
        }
        return clean;
    }

    function requireLength(input: string | undefined, length: number): string | undefined {
        if ((input ?? '').length < length) {
            return;
        }
        return input;
    }

    return (
        <>
            <Backdrop show={isProcessing} />
            <div className='fs-3 fw-bold mb-3'>Invite Team Member</div> {/* STYLE */}
            <div>Complete the requested fields to create a new account for a team member.</div>
            <div className={styles.invite}>
                <div>
                    {/* <Form noValidate> */}
                    <Form.Group>
                        <Form.Label className={styles.invitelabel} htmlFor='newemail'>Email:</Form.Label>
                        <Form.Control
                            id='newemail'
                            ref={inputRef}
                            tabIndex={0}
                            isInvalid={isValidated && (validation.email == null)}
                            placeholder='Where to send the invitation'
                            value={userEmail}
                            onChange={e => setUserEmail(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Invalid email address.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className={styles.invitelabel} htmlFor='newuser'>Login:</Form.Label>
                        <br />
                        <Form.Control
                            id='newuser'
                            tabIndex={0}
                            isInvalid={isValidated && validation.user == null}
                            placeholder='A unique login username'
                            value={userName}
                            onChange={e => setUserName(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Incorrect format.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className={styles.invitelabel} htmlFor='newdisplay'>Name:</Form.Label>
                        <Form.Control
                            id='newdisplay'
                            tabIndex={0}
                            isInvalid={isValidated && validation.display == null}
                            placeholder='Their preferred name'
                            value={userDisplay}
                            onChange={e => setUserDisplay(e.target.value)} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            Required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* </Form> */}
                </div>
                <div className={styles.inviteaction}>
                    <Button
                        type='reset'
                        variant='secondary'
                        tabIndex={0}
                        onClick={onReset}>Reset</Button>
                    &nbsp;
                    <Button
                        type='submit'
                        disabled={!isFormComplete()}
                        variant={isFormComplete() ? 'primary' : 'outline-primary'}
                        tabIndex={0}
                        onClick={onInvite}>Invite</Button>
                </div>
            </div>
            <div className={styles.note}>
                <div className='fw-bold'>Note:</div>
                <ul>
                    <li>Email must be properly formatted.</li>
                    <li>You may be update email and resend invite should a correction be required.</li>
                    <li>Login must be at least 6 characters consisting on only letters, numbers, and dashes.</li>
                </ul>
            </div>
        </>
    );
}

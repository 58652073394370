import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import * as AppPaths from '../../appPaths'
import '../../styles/auth.css'
import styles from './AuthSignin.module.css'

export default function Auth() {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [didFail, showFailure] = useState(false);
    const userRef = useRef<HTMLInputElement | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();

    function hasInput(): boolean {
        return user.length > 0 && password.length > 0
    }

    function navigateBack() {
        let from = location.state?.from?.pathname || AppPaths.home;
        switch (from) {
            case AppPaths.home:
            case AppPaths.signin:
                from = AppPaths.evalhome;
                break;
        }
        navigate(from, { replace: true });
    }

    function onHandleLogin() {
        auth.signin(user, password)
            .then(isSuccessful => {
                if (isSuccessful) {
                    navigateBack();
                } else {
                    showFailure(true);
                }});

    }

    return (
        <div className='auth-content'>
            <div className='h1 mt-5 mb-4'>Sign In</div>
            <div className='mb-4'>Enter your credentials to sign into your account:</div>
            <div className='auth-form'>
                <Form>
                    <Form.Group>
                        <Form.Label className='fw-bold' htmlFor='login-email'>Email Address:</Form.Label>
                        <Form.Control id='login-email' ref={userRef} type='email' placeholder='Enter email' onChange={(e) => setUser(e.currentTarget.value)} />
                    </Form.Group>
                    <Form.Group className='mt-4 mb-3'>
                        <Form.Label className='fw-bold' htmlFor='login-password'>Password:</Form.Label>
                        <Form.Control id='login-password' type='password' placeholder='Password' onChange={(e) => setPassword(e.currentTarget.value)} />
                    </Form.Group>
                    <Form.Group className='auth-actions'>
                        <Button variant='secondary' onClick={navigateBack}>
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            variant={hasInput() ? 'primary' : 'outline-primary'}
                            tabIndex={0}
                            disabled={!hasInput()}
                            onClick={onHandleLogin}>
                            Confim
                        </Button>
                    </Form.Group>
                </Form>
            </div>
            <Modal show={didFail} size='sm' centered backdrop autoFocus onHide={() => showFailure(false)}>
                <Modal.Header className={styles.failwindow}>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.failcontent}>
                    Unable to sign-in...
                </Modal.Body>
                <Modal.Footer as='div'>
                    <Button variant='danger' onClick={() => showFailure(false)}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { useActivateStore } from '../../store/activateStore'
import * as api from '../../services/apiDirectory'
import Backdrop from '../../components/general/Backdrop'
import PasswordChange from '../../components/settings/PasswordChange'
import * as AppPaths from '../../appPaths'

export default function AuthActivate() {
    const state = useActivateStore();
    const [didInit, setInit] = useState(false);
    const [isProcessing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    useEffect(() => {
        if (!didInit) {

            setInit(true);

            if (auth.isAuthenticated()) {
                navigate(AppPaths.evalhome);
                return;
            }

            const search = new URLSearchParams(location.search);
            const ticket = search.get('ticket');
            if (ticket == null) {
                navigate(AppPaths.home);
                return;
            }

            state.verifyTicket(ticket)
                .then(
                    (isValid) => {
                        if (!isValid) {
                            navigate(AppPaths.home);
                        }
                    })
                .catch(err => {
                    console.error(err);
                    navigate(AppPaths.home);
                });
        }
    }, [didInit, state, auth, location.search, navigate])

    function updatePassword(password: string) {
        if (state.isValid) {
            setProcessing(true);
            api.activateInvite(state.ticket, password)
                .then(didSucceed => {
                    if (didSucceed) {
                        navigate(AppPaths.signin)
                    } else {
                        setProcessing(false);
                        // showFailure(true); $$$ FAILURE TODO
                    }
                });
        }
    }

    return (
        <>
            <Backdrop text='Verifying...' show={!state.isValid || isProcessing} fullscreen={true} blur={true} />
            <div className='auth-content'>
                <div className='h1 mt-5 mb-4'>Activate Account</div>
                <div>Please create your password to activate your account:</div>
                <PasswordChange onChange={updatePassword} />
            </div>
        </>
    );
}

import { useEffect, useState } from 'react'
import { useAuth } from '../hooks/auth'
import { useIdle } from '../hooks/idle'
import * as AppPaths from '../appPaths'
import { config } from '../config'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-bootstrap'
import { Toast } from 'react-bootstrap'
import Footer from '../components/general/Footer'
import { ReactComponent as LogoIcon } from '../assets/notebook-exam.svg'
import { ReactComponent as ProfileIcon } from '../assets/profile.svg'
import { ReactComponent as ActivityIcon } from '../assets/fitness.svg'
import styles from './Main.module.css'

export default function Main() {
    console.log('MAIN: RENDER');
    const auth = useAuth();
    const idle = useIdle();
    const isAuthenticated = auth.isAuthenticated();
    const [timer, setTimer] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // console.log('IDLE: COUNTDOWN EFFECT');
        if (!auth.isAuthenticated()) {
            return;
        }
        let idleInterval: NodeJS.Timeout | undefined = undefined;
        if (idle.isIdle()) {
            console.log('IDLE: COUNTDOWN SET');
            const countdownTarget = Date.now() + config.app.InactivityCountdown;
            idleInterval = setInterval(() => {
                const countdown = countdownTarget - Date.now();
                if (countdown <= 0) {
                    console.log('IDLE: LOGOUT !!!');
                    auth.signout().then(() => navigate(AppPaths.home));
                } else {
                    setTimer(countdown);
                }
            }, 1000);
        }
        return () => {
            if (idleInterval) {
                console.log('IDLE: COUNTDOWN CLEAR');
                clearInterval(idleInterval)
                setTimer(0);
            }
        };
    }, [idle, auth, navigate]);

    function doLogin() {
        navigate(AppPaths.signin, { state: { from: location } });
    }

    function formatTimer() {
        const seconds = Math.trunc((timer % 60000) / 1000);
        return `${seconds} seconds`;
    }

    return (
        <div className='root'>
            <div className={`brand ${styles.brand}`}>
                <span><NavLink to={AppPaths.home}><LogoIcon />FaciliBus AI</NavLink></span>
                <span className={styles.auth}>
                    {isAuthenticated ?
                        <>
                            <NavLink to={AppPaths.evalhome}><ActivityIcon /></NavLink>
                            <NavLink to={AppPaths.profile}><ProfileIcon /></NavLink>
                            <span onClick={() => auth.signout()}>Sign Out</span>
                        </> :
                        <span onClick={() => doLogin()}>Sign In</span>}
                </span>
            </div>
            <div className='root-content'>
                <div className='root-control'>
                    <Outlet />
                </div>
                <Footer />
            </div>
            <ToastContainer
                className='p-3'
                hidden={timer === 0}
                position='bottom-end'
                style={{ zIndex: 1 }}>
                <Toast>
                    <Toast.Body className={styles.timeout}>You will be signed-out after {formatTimer()} due to inactivity.</Toast.Body>
                </Toast>
            </ToastContainer>
        </div >
    );
}

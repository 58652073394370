import { ReactComponent as Icon } from '../../assets/pin.svg'
import styles from './Company.module.css'

export default function About() {
  return (
    <>
      <div className={styles.image}><Icon /></div>
      <div className={styles.title}>About Us</div>
      <div className={styles.content}>
        <div>
          Facilibus is based on the latin word for "easy": "facilis" and
          may be roughly translated as "for easy things".
          Facilibus may also remind you of the work "syllabus":
          an outline of the subjects in a course of study or teaching.
        </div>
        <div>
          At our company, we are passionate about making AI technology accessible to businesses of all sizes.
          We believe that AI can revolutionize staff performance by providing advanced training solutions and realistic simulations of customer and staff interactions.
        </div>
        <div>
          Our tools help teams sharpen their skills in a controlled environment, allowing employees to practice and improve their responses to various scenarios without the risks of real-world consequences.
          By integrating AI-driven simulations, we empower businesses to enhance their workforce's capabilities, ensuring they are well-prepared to deliver exceptional service and collaborate effectively in dynamic environments.
        </div>
        <div>
          For management and leadership, having the ability to monitor training feedback through AI-powered simulations offers immense value.
          It provides real-time insights into individual and team performance, allowing leaders to track progress, identify skill gaps, and tailor training programs accordingly. 
          This data-driven feedback helps management make informed decisions about employee development, ensuring that training efforts are both effective and efficient. 
          Additionally, by observing how employees handle simulated interactions, leadership can identify high-potential talent, improve coaching strategies, and foster a culture of continuous improvement. 
        </div>
      </div>
    </>);
}

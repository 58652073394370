import { useEffect, useState } from 'react'
import { Placeholder } from 'react-bootstrap'
import renderIf from '../../lib/renderIf'
import * as api from '../../services/apiContent'
import * as xtypes from 'xtypes'
import { ReactComponent as Icon } from '../../assets/rocket.svg'
import styles from './Company.module.css'

export default function Careers() {
  const [positions, setPositions] = useState<xtypes.site.IPosition[]>([]);
  const [didInit, setInit] = useState(false);

  useEffect(() => {
    if (!didInit && positions.length === 0) {
      api.getPositions().then(
        data => {
          setPositions(data);
          setInit(true);
        });
    }
  }, [didInit, positions])

  return (
    <>
      <div className={styles.image}><Icon /></div>
      <div className={styles.title}>Ready for a Change?</div>
      <div className={styles.content}>
        <div>Are you looking for a special team with abundant growth potential?</div>
        <div>We're looking for special individuals who ...</div>
        {/* PLACEHOLDER */}
        {
          renderIf(
            positions.length === 0,
            <>
              <Placeholder animation="glow">
                <Placeholder xs={4} size='lg' />
              </Placeholder>
              <br/>
              <Placeholder animation="glow">
                <Placeholder xs={2} size='lg' />
                &nbsp;
                <Placeholder xs={2} size='lg' />
                &nbsp;
                <Placeholder xs={2} size='lg' />
              </Placeholder>
              <br/>
              <Placeholder animation="glow">
                <Placeholder xs={3} size='lg' />
                &nbsp;
                <Placeholder xs={3} size='lg' />
              </Placeholder>
            </>)
        }
        {
          positions.map((p, index) => <div key={index}>{p.position}</div>)
        }
      </div >
    </>);
}

import { Control, FieldValues, useFormContext, UseFormRegister, useFieldArray } from 'react-hook-form';
import renderIf from '../../lib/renderIf'
import * as dynamic from './DynamicTypes'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { ReactComponent as AddIcon } from '../../assets/add.svg'
import { ReactComponent as RemoveIcon } from '../../assets/remove.svg'
import styles from './DynamicFormField.module.css'

interface DynamicFormFieldProps {
    field: dynamic.FormField;
    default: any;
}

export default function DynamicFormField(props: DynamicFormFieldProps) {
    const { control, register } = useFormContext();
    const isArray = Array.isArray(props.field);
    const formField = dynamic.getField(props.field);
    console.log(`DYNAMIC FIELD: ${formField.id} ${isArray} "${JSON.stringify(props.default) ?? '-'}"`);
    return (
        <OverlayTrigger
            placement="left-start"
            delay={{ show: 500, hide: 100 }}
            show={formField.description == null ? false : undefined}
            overlay={<Popover body={true}>{formField.description}</Popover>}
        >
            {({ ref, ...triggerHandler }) => (
                <div className='mb-3' {...triggerHandler}>
                    {renderIf(formField.element !== 'option',
                        <label className='form-label fw-bold' htmlFor={formField.id} ref={ref}>{`${formField.label}:`}</label>
                    )}
                    {
                        isArray ?
                            DynamicArrayElement(register, control, formField, props.default, ref) :
                            DynamicFormElement(register, formField, props.default, ref)
                    }
                </div>
            )}
        </OverlayTrigger>
    );
}

function DynamicArrayElement(
    register: UseFormRegister<FieldValues>,
    control: Control<FieldValues, any>,
    formField: dynamic.IFormField,
    defaultValue: any,
    ref: React.Ref<any>) {

    // console.log(`ARRAY: ${formField.id}`);

    const { fields, append, remove } = useFieldArray<FieldValues>({
        name: formField.id,
        control
    });

    // console.log(defaultValue);
    // console.log(fields);

    return (
        <>
            {
                fields.map((field, index) =>
                    <div key={`fld${formField.id}${index}`} className='d-flex flex-row justify-content-between align-items-center mb-1'>
                        {DynamicFormElement(register, formField, defaultValue[index], ref, index, field.id)}
                        {renderIf(index > 0,
                            <div className='ms-1'>
                                <button
                                    className={`btn btn-outline-danger btn-sm`}
                                    type='button'
                                    onClick={() => remove(index)}>
                                    <RemoveIcon className={`${styles.action} ${styles.remove}`} />
                                </button>
                            </div>
                        )}
                    </div>)
            }
            <div className='d-flex flex-row justify-content-end ps-1'>
                <button
                    className='btn btn-outline-success btn-sm'
                    type='button'
                    onClick={() => append(dynamic.getFieldDefault(formField))}>
                    <AddIcon className={`${styles.action} ${styles.add}`} />
                </button>
            </div>
        </>);
}

function DynamicFormElement(
    register: UseFormRegister<FieldValues>,
    formField: dynamic.IFormField,
    defaultValue: any,
    ref: React.Ref<any>,
    index?: number,
    key?: string) {
    const registrationId = index == null ? formField.id : `${formField.id}.${index}` as const;
    // console.log(`REGISTERID ${registrationId}`);
    switch (formField.element) {
        case 'select': {
            return (
                <select
                    className='form-control'
                    id={formField.id}
                    key={key}
                    defaultValue={defaultValue}
                    {...register(registrationId, formField.config)}
                >
                    {(formField.options ?? []).map((o, index) => (
                        <option key={`opt${formField.id}${index}`} value={o.value}>
                            {o.label}
                        </option>
                    ))}
                </select>
            );
        }
        case 'textarea':
            return (
                <textarea
                    className='form-control'
                    id={formField.id}
                    key={key}
                    tabIndex={0}
                    placeholder={formField.placeholder}
                    defaultValue={defaultValue}
                    {...register(registrationId, formField.config)} />);
        case 'choice':
            return (
                <>
                    {(formField.options ?? []).map((o, index) => (
                        <div className='form-check' key={`opt${formField.id}${index}`}>
                            <input
                                className='form-check-input'
                                id={o.value}
                                type='radio'
                                tabIndex={0}
                                value={o.value}
                                placeholder={formField.placeholder}
                                {...register(registrationId, formField.config)} />
                            <label className='form-check-label' htmlFor={o.value}>{o.label}</label>
                        </div>
                    ))}
                </>
            );
        case 'option':
            return (
                <div className='form-check fw-bold' ref={ref}>
                    <input
                        className='form-check-input'
                        id={formField.id}
                        key={key}
                        type='checkbox'
                        tabIndex={0}
                        placeholder={formField.placeholder}
                        defaultChecked={defaultValue}
                        {...register(registrationId, formField.config)} />
                    <label className='form-check-label' htmlFor={formField.id}>{formField.label}</label>
                </div>
            );
        case 'number':
            return (
                <input
                    className='form-control'
                    id={formField.id}
                    key={key}
                    type='number'
                    tabIndex={0}
                    placeholder={formField.placeholder}
                    defaultValue={defaultValue}
                    {...register(registrationId, formField.config)} />
            );
        case 'text':
        default:
            return (
                <input
                    className='form-control'
                    id={formField.id}
                    key={key}
                    type='text'
                    tabIndex={0}
                    placeholder={formField.placeholder}
                    defaultValue={defaultValue}
                    {...register(registrationId, formField.config)} />);
    }
}


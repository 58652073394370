import { useState } from 'react'
import classNames from 'classnames';
import useInit from '../../hooks/init';
import renderIf from '../../lib/renderIf'
import Backdrop from '../../components/general/Backdrop'
// import { Table } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { useProfileStore } from '../../store/profileStore'
import { useMemberStore } from '../../store/memberStore'
import { theme } from '../../hooks/theme'
import styles from './UserProfile.module.css'

interface SettingsSectionProps {
  text: string;
  children: React.ReactNode;
}

export default function UserProfile() {
  const stateTeam = useMemberStore();
  const stateProfile = useProfileStore();
  const [themeName, setTheme] = useState(theme.get());
  const [editProfile, setEditProfile] = useState(false);

  const init = useInit(stateProfile, stateTeam);

  function onChangeTheme(themeName: string) {
    theme.set(themeName);
    setTheme(themeName);
  }

  const SettingsSection = (props: SettingsSectionProps) =>
    <div className='mb-2'>
      <div className='fs-5 fw-bold text-secondary-emphasis'>{props.text}:</div>
      <div className={classNames(styles.section, 'container')}>{props.children}</div>
    </div>

  return (
    <>
      <div className='fs-3 fw-bold mb-3'>Your Account</div>
      {renderIf(init.ready(),
        <>
          <SettingsSection text='Profile'>
            <div className='row'>
              <div className='col-9'>
                <div className='row align-items-center'>
                  <div className='col-4 fw-bold'>
                    Organization:
                  </div>
                  <div className='col-8 ps-0'>
                    <div className={styles.notcontrol}>{stateTeam.self?.organization}</div>
                  </div>
                  <div className='col-4 fw-bold'>
                    User&nbsp;Name:
                  </div>
                  <div className='col-8 ps-0'>
                    <div className={styles.notcontrol}>{stateProfile.principal?.name}</div>
                  </div>
                  <div className='col-4 fw-bold'>
                    Teams:
                  </div>
                  <div className='col-8 ps-0'>
                    <div className={styles.notcontrol}>
                      {
                        stateTeam.self?.teams.map(t => <div key={t.name}>{t.name}</div>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SettingsSection>
          <SettingsSection text='Contact'>
            <div className='row'>
              <div className='col-9'>
                <div className='row align-items-center'>
                  <div className='col-4 fw-bold'>
                    Email:
                  </div>
                  <div className='col-8 ps-0'>
                    {renderIf(editProfile,
                      <input
                        type='email'
                        className='form-control'
                        tabIndex={0}
                        defaultValue={stateProfile.principal?.email} />,
                      <div className={styles.notcontrol}>{stateProfile.principal?.email}</div>
                    )}
                  </div>
                  <div className='col-4 fw-bold'>
                    Display&nbsp;Name:
                  </div>
                  <div className='col-8 ps-0 pt-1'>
                    {renderIf(editProfile,
                      <input
                        type='text'
                        className='form-control'
                        tabIndex={0}
                        defaultValue={stateProfile.principal?.display} />,
                      <div className={styles.notcontrol}>{stateProfile.principal?.display}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-3'>
                <div className={`d-flex flex-row justify-content-end ${styles.actions}`}>
                  {renderIf(!editProfile,
                    <Button size='sm' onClick={() => setEditProfile(true)}>Edit</Button>,
                    <>
                      <Button size='sm' variant='secondary' className='me-1' onClick={() => setEditProfile(false)}>Cancel</Button>
                      <Button size='sm' variant='success' type='submit'>Save</Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </SettingsSection>
          <SettingsSection text='Settings'>
            <div className='row pt-1'>
              <div className='col-3 fw-bold'>
                Display:
              </div>
              <div className='col-9'>
                <div>
                  <Form.Check
                    inline
                    type='radio'
                    name='theme'
                    label='Light'
                    value='light'
                    id='theme-light'
                    checked={themeName === 'light'}
                    onChange={() => onChangeTheme('light')}
                  />
                </div>
                <div>
                  <Form.Check
                    inline
                    type='radio'
                    name='theme'
                    label='Dark'
                    value='dark'
                    id='theme-dark'
                    checked={themeName === 'dark'}
                    onChange={() => onChangeTheme('dark')}
                  />
                </div>
                <div>
                  <Form.Check
                    inline
                    type='radio'
                    name='theme'
                    label='Auto'
                    value='auto'
                    id='theme-auto'
                    checked={themeName === 'auto'}
                    onChange={() => onChangeTheme('auto')}
                  />
                </div>
              </div>
            </div>
          </SettingsSection >
        </>,
        <Backdrop text='Loading...' />
      )
      }
    </>
  );
}


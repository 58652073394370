import { useEffect, useState } from 'react'
import IManagedState from '../store/IManagedState'

type InitState = 'none' | 'started' | 'done' | 'force';

export default function useInit(...states: IManagedState[]) {
    const [didInit, setInit] = useState<InitState>('none');
    
    useEffect(() => {
        if (didInit === 'none' || didInit === 'force') {
            setInit('started');
            initStores(states, didInit === 'force')
                .then(() => setInit('done'));
        }
    }, [didInit, states]);

    return {
        ready: (): boolean => {
            return didInit === 'done' && isInitialized(states)
        },
        refresh: () => {
            setInit('force');
        }
    }
}

function isInitialized(states: IManagedState[]): boolean {
    return states.filter(s => s.didInit).length === states.length;
}

function initStores(states: IManagedState[], force: boolean): Promise<void[]> {
    const inits = [];
    for (const state of states) {
        if (force || !state.didInit) {
            inits.push(state.init());
        }
    }
    return Promise.all(inits);
}

import { config } from '../config'
import log from 'loglevel';

const defaultEndpoint = `ws://${document.location.hostname}:${config.app.PortSTT}`;

export class Transcriber {
    private socket: WebSocket | undefined = undefined;
    private callback: ((text: string) => void) | undefined = undefined;

    constructor(private readonly endpoint: string = defaultEndpoint) { }

    public attach(callback: (text: string) => void) {
        this.callback = callback;
    }

    public detach() {
        this.callback = undefined;
    }

    public open(): WebSocket {
        if (this.socket != null && this.socket.readyState < 2) {
            return this.socket;
        }

        this.socket = new WebSocket(this.endpoint);

        this.socket.addEventListener('open', () => {
            log.debug('socket: connected to server');
        });

        this.socket.addEventListener('message', (event) => {
            const text = event.data as string;
            log.debug(`socket: recieved "${text}"`);
            if (this.callback != null) {
                this.callback(text);
            }

            // if (event.data === '') {
            //     return;
            // }

            // if (data && data.channel && data.channel.alternatives[0].transcript !== '') {
            //     const text = data.channel.alternatives[0].transcript;
            //     log.debug(`socket: ${text}`);
            //     if (this.callback != null) {
            //         this.callback(text);
            //     }
            // }
        });

        this.socket.addEventListener('close', () => {
            log.debug('socket: disconnected from server');
        });

        return this.socket;
    }

    // private static parseData(data: string): any {
    //     try {
    //         return JSON.parse(data);
    //     } catch (e) {
    //         throw Error(`Failed to parse JSON: ${e}`);
    //     }
    // }
}
import renderIf from '../../lib/renderIf'
import ContentList from '../general/ContentList'
import * as xtypes from 'xtypes'


export interface AssignmentListProps {
    assignments: xtypes.db.WithId<xtypes.apps.IAssignmentInfo>[];
    onSelect?: (assignment: xtypes.db.WithId<xtypes.apps.IAssignmentInfo>) => void;
}

export default function AssignmentList(props: AssignmentListProps) {
    return (
        <ContentList title='Assignments'>
            {renderIf(props.assignments?.length === 0,
                <div className='text-secondary'>None</div>,
                <div className='container p-0 mt-2 mb-2'> {/* SCROLLABLE */}
                    {props.assignments.map((assignment, index) =>
                        <div
                            className='action row'
                            key={`ass-${index}`}
                            onClick={() => props.onSelect?.call(null, assignment)}
                        >
                            <div className='col-5 text-truncate'>{assignment.scenario}</div>
                            <div className='col-4 text-truncate'>{assignment.exercise}</div>
                            <div className='col-3'>{assignment.whenassigned.toLocaleDateString()}</div>
                        </div>
                    )}
                </div>
            )}
        </ContentList>
    );
};

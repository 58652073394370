import React from 'react'
import ReactDOM from 'react-dom/client'
import log from 'loglevel'
import { config } from './config'
import { theme } from './hooks/theme'
import App from './App'
import 'bootstrap/dist/css/bootstrap.css'
import './styles/root.css'

async function init(): Promise<void> {
  switch (process.env.NODE_ENV) {
    case 'production':
      log.setLevel('warn');
      break;
    case 'test':
      log.setLevel('info');
      break;
    case 'development':
    default:
      log.setLevel('debug');
      break;
  }

  await config.load();
  theme.config();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

init();

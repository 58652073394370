import { useState } from 'react'
import useInit from '../../hooks/init';
import classnames from 'classnames'
import renderIf from '../../lib/renderIf'
import Backdrop from '../../components/general/Backdrop'
import PopupTip from '../../components/general/PopupTip'
import ContentBox from '../../components/general/ContentBox'
import { Button } from 'react-bootstrap'
import { ToggleButton } from 'react-bootstrap'
import { useAdminTeamStore } from '../../store/adminTeamsStore'
import { useAdminDirectoryStore } from '../../store/adminDirectoryStore'
import { ReactComponent as CollapseIcon } from '../../assets/collapseall.svg'
import { ReactComponent as ExpandIcon } from '../../assets/expandall.svg'
import styles from './Teams.module.css'

export default function Teams() {
  const stateDirectory = useAdminDirectoryStore();
  const stateTeams = useAdminTeamStore();
  const init = useInit(stateDirectory, stateTeams);

  const [expandTeams, setExpandTeams] = useState<boolean>();
  const [showAssigned, setShowAssigned] = useState<boolean>(false);

  function doRefresh() {
    setExpandTeams(undefined);
    init.refresh();
  }

  // $$$ NEW TEAM
  // $$$ DISABLE TEAM 
  // $$$ ADD / REMOVE MEMBER
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-3'>
        <div className='fs-3 fw-bold'>Teams</div>
        <div className='d-grid gap-2 d-md-flex justify-content-md-end align-items-center'>
          <PopupTip text={`Toggle to ${showAssigned ? 'hide' : 'show'} unnassigned personnel`}>
            <ToggleButton
              size='sm'
              id='toggleView'
              type='checkbox'
              disabled={!init.ready()}
              variant={showAssigned ? 'primary' : 'outline-primary'}
              checked={showAssigned}
              value={1}
              onChange={() => setShowAssigned(!showAssigned)}
            >
              Show All
            </ToggleButton>
          </PopupTip>
          <PopupTip text='Reload the latest data'>
            <Button
              size='sm'
              disabled={!init.ready()}
              onClick={() => doRefresh()}>
              Refresh
            </Button>
          </PopupTip>
        </div>
      </div>
      <div className='container'>
        <div className={classnames('row', styles.heading)}>
          <div className='col-4'>
            Personnel
          </div>
          <div className='col-1'>
          </div>
          <div className='col-7 d-flex flex-row justify-content-between'>
            <div>Groups</div>
            <div>
              <CollapseIcon className='action' onClick={() => setExpandTeams(false)} />
              <ExpandIcon className='action' onClick={() => setExpandTeams(true)} />
            </div>
          </div>
        </div>
        {renderIf(init.ready(),
          <div className='row'>
            <div className='col-4'>
              {(stateDirectory.users ?? []).filter(user => !user.disabled && (showAssigned || !((stateTeams.members ?? {})[user.name]?._teams.length >= 0)))
                .map(
                  (user) =>
                    <ContentBox key={user.name}>
                      <ContentBox.Header flat={true}>
                        {user.display}
                      </ContentBox.Header>
                    </ContentBox>
                )}
            </div>
            <div className='col-1'>
            </div>
            <div className='col-7'>
              {(stateTeams.teams ?? [])?.map(
                (team, index) =>
                  <ContentBox key={`team${index}`} disabled={team.disabled} expanded={expandTeams}>
                    <ContentBox.Header>
                      <div className={classnames({ 'action': team.disabled !== true })} onDoubleClick={() => team.disabled !== true ? alert('!!!') : 1}>{team.name}</div>
                    </ContentBox.Header>
                    <ContentBox.Body>
                      <div className='row'>
                        {renderIf(
                          team.members.length > 0,
                          <>
                            {team.members
                              .map((name) => stateDirectory.index[name])
                              .map(
                                (user) =>
                                  <div key={`team${index}:${user.name}`} className={classnames('col-6', { [styles.disabled]: user.disabled })}>
                                    {user.display} - {user.name}
                                  </div>
                              )}
                          </>,
                          <div>(None)</div>
                        )}
                      </div>
                    </ContentBox.Body>
                  </ContentBox>
              )}
            </div>
          </div>
          ,
          <Backdrop text='Loading...' />
        )}
      </div>
    </>
  );
}

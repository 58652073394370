import { config } from '../config'
import log from 'loglevel';
import * as xtypes from 'xtypes'

const defaultEndpoint = `ws://${document.location.hostname}:${config.app.PortTTS}`;

export class Speaker {

    public readonly audio: Uint8Array[] = [];

    constructor(private readonly endpoint: string = defaultEndpoint) { }

    public generate(text: string, model: string, callback: (url: string) => void) {
        const request: xtypes.speech.SpeechRequest = {
            model,
            text,
        };

        let url: string | undefined = undefined;
        // let error: Error | undefined = undefined;

        const socket = new WebSocket(this.endpoint);

        socket.addEventListener('open', () => {
            log.debug('socket: connected to server');
            const payload = JSON.stringify(request);
            socket.send(payload);
        });

        socket.addEventListener('message', (event) => {
            log.debug('socket: recieved audio');
            this.audio.push(event.data);
        });

        socket.addEventListener('close', () => {
            log.debug('socket: disconnected from server');
            const audioData = this.audio.concat();
            this.audio.splice(0, this.audio.length);
            const blob = new Blob(audioData, { type: "audio/mpeg" });
            url = window.URL.createObjectURL(blob);
            callback(url);
        });
    }
}
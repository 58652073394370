import { FieldValues } from 'react-hook-form'
import { ObjectId } from 'bson'
import renderIf from '../../lib/renderIf'
import * as apiApps from '../../services/apiApps'
import Backdrop from '../general/Backdrop'
import DynamicForm from '../form/DynamicForm'
import DynamicFormField from '../form/DynamicFormField'
import * as dynamic from '../form/DynamicTypes'
import * as xtypes from 'xtypes'

const ScenarioNameField = 'exercisename';
const ScenarioDescriptionField = 'exercisedescription';

interface CreateScenarioProps {
    application: xtypes.apps.IApplicationInfo | undefined;
    exercise: xtypes.apps.IExerciseInfo | undefined;
    fields: dynamic.FormField[];
    template?: xtypes.docs.JSONNode;
    onSubmit: dynamic.FormSubmitHandler;
}

export function Form(props: CreateScenarioProps) {

    console.log(`SCENARIO FORM RENDER: ${props.fields.length}`);

    return (
        <>
            {renderIf(props.fields?.length > 0,
                <>
                    <label className='form-label fw-bold' htmlFor={ScenarioNameField}>Exercise Name:</label>
                    <div>
                        {props.exercise?.name}
                    </div>
                    <label className='form-label fw-bold' htmlFor={ScenarioDescriptionField}>Exercise Description:</label>
                    <div>
                        {props.exercise?.description}
                    </div>
                    <DynamicForm
                        onSubmit={props.onSubmit}
                        defaultValues={dynamic.getFormDefaults(props.fields, props.template)}>
                        <>
                            {props.fields.map((field, index) =>
                                <div key={`field${index}`}>
                                    <DynamicFormField
                                        field={field}
                                        default={dynamic.getFieldDefault(field, props.template)}
                                    />
                                </div>
                            )}
                        </>
                    </DynamicForm>
                </>,
                <Backdrop text='Loading...' />
            )}
        </>);
}

export async function CreateScenario(
    data: FieldValues,
    exerciseId: ObjectId,
): Promise<void> {
    const request = {
        exerciseId,
        template: data
    } as xtypes.docs.IScenarioGeneratorRequest;
    await apiApps.createScenario(request);
}



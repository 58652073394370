// import useInit from '../../hooks/init';
// import { Table } from 'react-bootstrap'
// import { Form } from 'react-bootstrap'
// import { useProfileStore } from '../../store/profileStore'
// import { useMemberStore } from '../../store/memberStore'

export default function Assignments() {
  // const stateTeam = useMemberStore();
  // const stateProfile = useProfileStore();

  return (
    <>
      <div className='fs-3 fw-bold mb-3'>Assign</div>
    </>
  );
}

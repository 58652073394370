import { create } from 'zustand'
import IManagedState from './IManagedState'
import * as apiApps from '../services/apiApps'
import * as apiChat from '../services/apiChat'
import * as xtypes from 'xtypes'

export interface MemberState extends IManagedState {
    self?: xtypes.db.WithId<xtypes.apps.ITeamMemberInfo>;
    assignments: xtypes.db.WithId<xtypes.apps.IAssignmentInfo>[];
    scenarios: xtypes.db.WithId<xtypes.apps.IScenarioInfo>[];
    sessions: xtypes.db.WithId<xtypes.apps.ISessionInfo>[];
    getSessions: () => Promise<void>;
}

export const useMemberStore = create<MemberState>((set) => ({
    didInit: false,
    error: undefined,
    assignments: [],
    scenarios: [],
    sessions: [],
    clearError: () => set({ error: undefined }),
    init: async () => {
        try {
            const assignmentsPromise = apiChat.getAssignments();
            const scenariosPromise = apiChat.getScenarios();
            const sessionsPromise = apiChat.getSessions();
            const selfPromise = apiApps.getSelf();
            const [self, assignments, scenarios, sessions] = await Promise.all([selfPromise, assignmentsPromise, scenariosPromise, sessionsPromise])
            set({ didInit: true, self, assignments, scenarios, sessions });
        } catch (err: any) {
            set({ error: err });
        }
    },
    getSessions: async () => {
        try {
            const sessions = await apiChat.getSessions();
            set({ sessions });
        } catch (err: any) {
            set({ error: err });
        }
    },
}));

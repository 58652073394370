import renderIf from '../../lib/renderIf'
import ContentList from '../general/ContentList'
import * as xtypes from 'xtypes'

export interface ScenarioListProps {
    scenarios: xtypes.db.WithId<xtypes.apps.IScenarioInfo>[];
    onSelect?: (scenario: xtypes.db.WithId<xtypes.apps.IScenarioInfo>) => void;
}

export default function ScenarioList(props: ScenarioListProps) {

    return (
        <ContentList title='Scenarios'>
            {renderIf(props.scenarios.length === 0,
                <div className='text-secondary'>None</div>,
                <div className='container p-0 mt-2 mb-2'> {/* SCROLLABLE */}
                    {props.scenarios?.length === 0 ? 'None' : ''}
                    {props.scenarios.map((scenario, index) =>
                        <div
                            className='action row'
                            key={`sce-${index}`}
                            onClick={() => props.onSelect?.call(null, scenario)}
                        >
                            <div className='col-5 text-truncate'>{scenario.name}</div>
                            <div className='col-7 text-truncate'>{scenario.exercise}</div>
                        </div>
                    )}
                </div>
            )}
        </ContentList>
    );
};

import { NavLink, Outlet } from 'react-router-dom'
import { useAuth } from '../hooks/auth'
import * as AppPaths from '../appPaths'
import '../styles/nav.css'
// import styles from './Settings.module.css'

export default function Settings() {
    const auth = useAuth();

    return (
        <div className='nav-container'>
            <div className='nav-categories'>
                <ul>
                    <li><NavLink to={AppPaths.profile}>Account</NavLink></li>
                    <li><NavLink to={AppPaths.password}>Password</NavLink></li>
                    {
                        // auth.hasRole(xtypes.auth.Roles.operator) ? (
                        auth.hasRole('operator') ? (
                            <>
                                <li><NavLink to={AppPaths.users}>Users</NavLink></li>
                                <li><NavLink to={AppPaths.userinvite}>Invite</NavLink></li>
                                <li><NavLink to={AppPaths.teams}>Teams</NavLink></li>
                                <li><NavLink to={AppPaths.training}>Training</NavLink></li>
                                <li><NavLink to={AppPaths.teamassign}>Assign</NavLink></li>
                            </>
                        ) : (<></>)
                    }
                </ul>
            </div>
            <div className='nav-content'>
                <Outlet />
            </div>
        </div>
    );
}

import { useEffect, useState, ReactNode } from 'react'
import { IdleContext } from '../hooks/idle'

export interface IdleProviderProps {
    children: ReactNode;
    threshold: number;
}

export function IdleProvider(props: IdleProviderProps) {

    const [isActive, setActive] = useState(true);

    console.log('IDLE: INIT');

    useEffect(() => {
        // console.log('IDLE: EVENT ATTACH');

        window.addEventListener('mousemove', onActivity);
        window.addEventListener('keypress', onActivity);
        window.addEventListener('touchstart', onActivity);

        let activityTimer: NodeJS.Timeout | undefined = undefined;

        if (isActive && !activityTimer) {
            onActivity();
        }

        function onActivity() {
            // console.log(`IDLE: ACTIVITY ${isActive}`);
            if (isActive === false) {
                setActive(true);
                return;
            }
            // console.log('IDLE: SET TIMER');
            clearTimeout(activityTimer);
            activityTimer = setTimeout(() => {
                console.log('IDLE: TRIGGER');
                setActive(false);
            }, props.threshold);
        }

        return () => {
            // console.log('IDLE: EVENT DETACH');
            window.removeEventListener('mousemove', onActivity);
            window.removeEventListener('keypress', onActivity);
            window.removeEventListener('touchstart', onActivity);
            if (activityTimer) {
                // console.log('IDLE: CLEAR TIMER');
                clearTimeout(activityTimer);
            }
        };
    }, [isActive, props.threshold]);

    const isIdle = (): boolean => {
        return !isActive;
    }

    const value = { isIdle };

    return <IdleContext.Provider value={value}>{props.children}</IdleContext.Provider>;
}

import { create } from 'zustand'
import IManagedState from './IManagedState'
import { dictionary } from '../lib/array'
import * as apiApps from '../services/apiApps'
import * as xtypes from 'xtypes'

export interface AdminTeamState extends IManagedState {
    teams?: xtypes.apps.ITeamInfo[];
    members?: Record<string, xtypes.db.WithId<xtypes.apps.ITeamMember>>;
}

export const useAdminTeamStore = create<AdminTeamState>((set) => ({
    didInit: false,
    error: undefined,
    clearError: () => set({ error: undefined }),
    init: async () => {
        try {
            const teamsPromise = apiApps.getTeams();
            const memberPromise = apiApps.getTeamMembers();
            const [teams, members] = await Promise.all([teamsPromise, memberPromise]);
            set({ didInit: true, teams, members: dictionary(members ?? [], (m) => m.name)});
        } catch (err: any) {
    set({ error: err });
}
    },
}));

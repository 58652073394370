import { useEffect, useRef } from 'react'
import * as xtypes from 'xtypes'
import styles from './ChatHistory.module.css'

export interface ChatHistoryProps {
    history: xtypes.apps.ISessionMessage[],
    nameInput: string,
    nameResponse: string
}

export function ChatHistory(props: ChatHistoryProps) {

    const chatRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (chatRef.current != null) {
            chatRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [props.history]);

    return (
        <div className={styles.history}>
            {props.history.map((m, index) =>
                <div
                    key={`msg${index}`}
                    ref={index === props.history.length - 1 ? chatRef : null}
                    className={m.role === 'input' ? styles.input : styles.response}
                >
                    <span><b>{(m.role === 'input' ? props.nameInput : props.nameResponse)}</b>: {m.content}</span>
                </div>)}
        </div>
    );
}
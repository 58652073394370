import { FieldValues, RegisterOptions } from 'react-hook-form'
import * as dynamic from "./DynamicTypes";

type SchemaNode = FieldValues;

export function fromSchema(schema: FieldValues): dynamic.FormField[] {
    const fields = [] as dynamic.FormField[];
    processNode(fields, schema);
    return fields;
}

function processNode(fields: dynamic.FormField[], currentNode: SchemaNode, key?: string) {

    // console.log(`# ${key ?? 'root'}`);

    const type: string = currentNode['type'] as string;
    if (key == null && type == null) {
        return; // Empty schema
    }

    switch (type) {
        case 'object':
            fields.push(...processObject(currentNode));
            break;
        case 'array':
            fields.push(processArray(currentNode, key));
            break;
        case 'string':
        case 'number':
        case 'boolean':
            fields.push(processField(currentNode, key));
            break;
        case 'null':
        default:
            throw new Error(`Unsupported type: ${type}.`);
    }
}

function processField(schemaNode: SchemaNode, key?: string): dynamic.IFormField {

    if (key == null) {
        throw new Error('Invalid schema: root element must be "object".');
    }

    const { label, type, element, description, placeholder, options, config } = schemaNode;

    // $$$ VALIDATE FIELD: label, type, element

    return {
        id: key,
        label: label as string,
        type: type as dynamic.FieldType,
        element: element as dynamic.FormElement,
        description: description as string | undefined,
        placeholder: placeholder as string | undefined,
        default: schemaNode.default as any,
        options: options as dynamic.IFieldOption[],
        config: config as RegisterOptions | undefined,
    };
}

function processArray(currentNode: SchemaNode, key?: string): dynamic.FormField {

    if (key == null) {
        throw new Error('Invalid schema: root element must be "object".');
    }

    const items = currentNode['items'] as SchemaNode;

    if (items == null) {
        throw new Error('Array missing "items" definition');
    }

    const field = processField(currentNode, key);
    const type = items['type'] as string;
    switch (type) {
        case 'object':
        case 'array':
        case 'null':
            throw new Error(`Unsupported array type: ${type}.`);
    }

    field.type = type as dynamic.FieldType;

    return [field];
}

function processObject(currentNode: SchemaNode): dynamic.FormField[] {

    const fields = [] as dynamic.FormField[];

    const properties = currentNode['properties'] as SchemaNode;

    if (properties == null) {
        throw new Error('Object missing "properties" definition');
    }

    for (const property in properties) {
        processNode(fields, properties[property] as SchemaNode, property);
    }

    return fields;
}


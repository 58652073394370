import { useRef } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import Footer from '../../components/general/Footer'
import thumbsup from '../../assets/thumbsup.svg'
import styles from './Try.module.css'

export default function Landing1() {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div>

      <div className={styles.header}>
        <div className='d-flex flex-row'>
          <img src={thumbsup} alt='thumbsup' />
          <span>It can't get any easier than this!</span>
        </div>
      </div>

      <div className={`d-flex flex-row ${styles.title}`}>
        <span>Automated Workplace Training</span>
      </div>

      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.blurb}>
            What was your most recent experience with workplace training?
          </div>
          <div className={styles.text}>
            Most training experience falls into one of these buckets:
          </div>
          <div className={styles.categories}>
            <ol>
              <li>"On the Job" (None)</li>
              <div>
                Most common.  Hard to know what's working.  Poor feedback / visibility.
                Training only for the "big guys"...
              </div>
              <li>Contract Programs</li>
              <div>Costly, infrequent, ineffective...out of reach for most businesses.</div>
              <li>Home Grown</li>
              <div>Time consuming, difficult to maintain...let's face it: you've got better things to do.</div>
            </ol>
          </div>
          <div className={styles.blurb}>
            If your experience has left you convinced there must be a more effective and
            less costly solution . . .
          </div>
          <div className={`d-flex flex-row ${styles.emphasis}`}>...you just found it!</div>
          <div className={styles.text}>
            Custom, so easy...TBD...
          </div>
          <div>&nbsp;</div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.form}>

          <div className={styles.contact}>
            Contact us for more information and a demo:
          </div>

          <div className={styles.container}>
            <div className={styles.content}>
              <div>
                <Form>
                  <Form.Group>
                    <Form.Label className='fw-bold' htmlFor='contactname'>Name:</Form.Label>
                    <Form.Control id='contactname' tabIndex={0} ref={inputRef} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='fw-bold' htmlFor='contactemail'>Email:</Form.Label>
                    <Form.Control id='contactemail' type='email' tabIndex={0} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='fw-bold' htmlFor='contactphone'>Phone:</Form.Label>
                    <Form.Control id='contactphone' type='phone' tabIndex={0} placeholder='' />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='fw-bold' htmlFor='contactphone'>Note:</Form.Label>
                    <Form.Control id='contactnote' as="textarea" rows={3} tabIndex={0} placeholder='Optional...' />
                  </Form.Group>
                  <div className={styles.formaction}>
                    <Button variant='primary'>Contact Us</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Footer minimal={true} onContact={() => inputRef.current?.focus()} />

    </div>
  );
}

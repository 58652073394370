import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import { WithId } from 'mongodb'
import renderIf from '../../lib/renderIf'
import { Alert } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import { Fade } from 'react-bootstrap'
import { ToggleButton } from 'react-bootstrap'
import { Microphone } from '../../services/Microphone'
import { Transcriber } from '../../services/Transcriber'
import { AudioPlayer } from '../../services/AudioPlayer'
import { Speaker } from '../../services/Speaker'
import { ChatHistory } from '../../components/chat/ChatHistory'
import Backdrop from '../../components/general/Backdrop'
import { useChatStore } from '../../store/chatStore'
import { useMemberStore } from '../../store/memberStore'
import * as AppPaths from '../../appPaths'
// import * as xtypes from 'xtypes'
import './Chat.css'

const nameInput = 'You';
const nameResponse = 'Fred';
// const speechModel = 'aura-asteria-en';
const speechModel = 'aura-orion-en';

const microphone = new Microphone();
const transcriber = new Transcriber();
const player = new AudioPlayer();
const speaker = new Speaker();

// Re-initialize App with active session
// Initial message
// Title / Summary / Instructions
// Chat Cancel (Delete)
// Chat Stop
// Chat Pause
// Copy Chat / Conversation
// Timer
// Enable auto-submit
// Auto submit silence indicator

export default function Chat() {
    const stateChat = useChatStore();
    const stateSessions = useMemberStore(state => state.sessions);
    const [didInit, setInit] = useState(false);
    const [isComplete, setComplete] = useState(false);
    const [isListening, toggleListening] = useState(false);
    const [isSpeaking, toggleSpeech] = useState(true);
    const [inputText, setInputText] = useState('');
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!didInit && !stateChat.didInit) {
            setInit(true);
            if (stateChat.sessionId == null) {
                navigate(AppPaths.evalhome, { replace: true });
                return;
            }
            stateChat.loadChat(stateChat.sessionId);
            // .then((history) => {
            //     if (history.length > 0) {
            //         onSpeak(history[history.length - 1].content); // $$$ DOUBLE (STRICT)
            //     }
            // });
        }
    }, [didInit, stateChat, navigate])

    // Display error alert
    useEffect(() => {
        if (stateChat.error != null) {
            setTimeout(() => stateChat.clearError(), 2000);
        }
    }, [stateChat.error, stateChat])

    // Transcribe voice input when microphone is active
    useEffect(() => { // $$$ WORKING BUT NOT RIGHT
        if (!isListening) { return }

        function appendTranscript(text: string) {
            setInputText(inputText + ' ' + text);
        }

        // console.log('TRANSCRIBE ATTACH')
        transcriber.attach(appendTranscript);

        return () => {
            // console.log('TRANSCRIBE DETACH')
            transcriber.detach();
        };
    }, [isListening, inputText]);

    // Toggle microphone
    async function onToggleMic(checked: boolean): Promise<void> {
        try {
            if (checked) {
                const socket = transcriber.open();
                await microphone.open(socket);
            } else {
                microphone.close();
            }
            toggleListening(checked);
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };

    // Submit input on shift+enter
    function onKeyPressInput(event: React.KeyboardEvent) {
        if (event.key === 'Enter' && (event.shiftKey || event.metaKey)) {
            onNewInput();
        }
    }

    // Submit input
    function onNewInput() {
        if (inputText.length > 0 && stateChat.sessionId != null) {
            // setIsResponding(true);
            stateChat.addMessage(stateChat.sessionId, inputText, onMessageCreated);
            setInputText('');
            // setIsResponding(false);
        }
    }

    // // Create session on shift+enter $$$ SAVE
    // function onKeyPressNewChat(event: React.KeyboardEvent) {
    //     if (event.key === 'Enter' && (event.shiftKey || event.metaKey)) {
    //         setConfirmNewSession(true);
    //     }
    // }
    
    function onMessageCreated(text: string, completed: boolean) {
        if (completed) {
            setComplete(true);
            const sessions = stateSessions.filter(s => s._id === stateChat.sessionId);
            if (sessions.length > 0) {
                sessions[0].whencompleted = new Date();
            }
        }
        onSpeak(text);
    }


    // Speak text
    function onSpeak(text: string) {
        if (!isSpeaking) { return; }
        try {
            speaker.generate(text, speechModel, (url) => player.play(url));
        } catch (error) {
            console.log(`Error: ${error}`); // $$$ ALERT
        }
    }

    function isInputTypingActive(): boolean { // $$$ TOO EXTRA
        return isChatActive() && !isListening;
    }

    function isChatActive(): boolean {
        if (stateChat.session?.whencompleted != null) {
            return false;
        }
        return !isComplete;
    }

    return (
        <>
            <Container className='floaterror mt-2'>
                <Alert
                    variant='danger'
                    transition={Fade}
                    show={stateChat.error != null}>
                    {stateChat.error?.toString()}
                </Alert>
            </Container>
            {renderIf(stateChat.didInit,
                <>
                    <div className='d-flex justify-content-between border-bottom'>
                        <div className='d-flex flex-column flex-grow-1 me-5'>
                            <div className='d-flex justify-content-between'>
                                <div><span className='fw-bold'>Scenario:</span> {stateChat.session?.scenario}</div>
                                <div><span className='fw-bold'>Exercise:</span> {stateChat.session?.exercise}</div>
                            </div>
                            <div><span className='fw-bold'>Summary:</span> {stateChat.session?.summary}</div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Button
                                size='sm'
                                tabIndex={0}
                                variant='secondary'
                                disabled={!isChatActive()}
                            >
                                Cancel
                            </Button>
                            &nbsp;
                            <Button
                                size='sm'
                                tabIndex={0}
                                disabled={!isChatActive()}
                            >
                                Pause
                            </Button>
                        </div>
                    </div>
                    <div className='chat-container'>
                        <div className={isChatActive() ? 'chat-title' : 'chat-title chat-title-disabled'}>Conversation:</div>
                        <div className='chat chat-border'>
                            <ChatHistory history={stateChat.history} nameInput={nameInput} nameResponse={nameResponse} />
                        </div>
                        <div className={isChatActive() ? 'input-title' : 'input-title chat-title-disabled'}>
                            <label htmlFor='voiceInput'>Input:</label>
                        </div>
                        <textarea
                            className='input chat-border'
                            id='chatInput'
                            ref={inputRef}
                            tabIndex={isChatActive() ? 0 : -1}
                            readOnly={!isInputTypingActive()}
                            aria-describedby='voiceInputHelp'
                            placeholder='Provide response here...'
                            onChange={(e) => setInputText(e.currentTarget.value)}
                            onKeyDown={onKeyPressInput}
                            value={inputText} />
                        <div className='input-actions'>
                            <ToggleButton
                                id='toggle-speech'
                                type='checkbox'
                                tabIndex={0}
                                disabled={!isChatActive()}
                                variant={!isChatActive() ? 'outline-secondary' : isSpeaking ? 'warning' : 'info'}
                                checked={isChatActive() && isSpeaking}
                                value={isSpeaking ? 1 : 0}
                                onChange={(e) => toggleSpeech(!isSpeaking)}>
                                {isSpeaking ? 'Mute' : 'Voice'}{/* $$$ CLARIFY */}
                            </ToggleButton>
                            <span>
                                <ToggleButton
                                    id='toggle-listen'
                                    type='checkbox'
                                    tabIndex={0}
                                    disabled={!isChatActive()}
                                    variant={isListening ? 'danger' : isChatActive() ? 'success' : 'outline-success'}
                                    checked={isListening}
                                    value={isListening ? 1 : 0}
                                    onChange={async (e) => onToggleMic(e.currentTarget.checked)}>
                                    {isListening ? 'Type' : 'Speak'}{/* $$$ CLARIFY */}
                                </ToggleButton>
                                <span>&nbsp;</span>
                                <Button
                                    id='command-clear'
                                    itemType='checkbox'
                                    tabIndex={0}
                                    variant={isInputTypingActive() ? 'secondary' : 'outline-secondary'}
                                    disabled={!isInputTypingActive()}
                                    onClick={(e) => setInputText('')}>
                                    Clear
                                </Button>
                                <span>&nbsp;</span>
                                <Button
                                    id='command-send'
                                    itemType='checkbox'
                                    type='button'
                                    tabIndex={0}
                                    variant={isChatActive() ? 'primary' : 'outline-primary'}
                                    disabled={!isChatActive()}
                                    onClick={onNewInput}>
                                    Send
                                </Button>
                            </span>
                        </div>
                    </div >
                </>
            )}
            <Backdrop text='Connecting...' show={!stateChat.didInit} />
        </>
    );
}

import { create } from 'zustand'
import { ObjectId } from 'bson'
import IManagedState from './IManagedState'
import * as apiApps from '../services/apiApps'
import { FormField } from "../components/form/DynamicTypes";
import * as Parser from '../components/form/DynamicFieldParser'
import * as xtypes from 'xtypes'

export interface AdminExerciseState extends IManagedState {
    applications?: xtypes.db.WithId<xtypes.apps.IApplicationInfo>[];
    exercises?: xtypes.db.WithId<xtypes.apps.IExerciseInfo>[];
    scenarios?: Partial<Record<string, xtypes.db.WithId<xtypes.apps.IScenarioInfo>[]>>;
    application?: xtypes.db.WithId<xtypes.apps.IApplicationInfo>;
    exercise?: xtypes.db.WithId<xtypes.apps.IExerciseInfo>;
    scenario?: xtypes.db.WithId<xtypes.apps.IScenarioInfo>;
    fields?: FormField[];
    template?: xtypes.docs.JSONNode;
    mode?: 'exercise' | 'scenario' | 'view';
    edit?: boolean;
    clearForm: () => void;
    loadExercise: (applicationId: ObjectId, exercise?: xtypes.db.WithId<xtypes.apps.IExerciseInfo>, edit?: boolean) => Promise<void>;
    loadScenario: (exercise: xtypes.db.WithId<xtypes.apps.IExerciseInfo>) => Promise<void>;
    viewScenario: (scenario: xtypes.db.WithId<xtypes.apps.IScenarioInfo>) => Promise<void>;
    refreshExercises(): Promise<void>;
}

export const useAdminExerciseStore = create<AdminExerciseState>((set) => ({
    didInit: false,
    error: undefined,
    clearError: () => set({ error: undefined }),
    init: async () => {
        try {
            const exercisesPromise = apiApps.getExercises();
            const scenariosPromise = apiApps.getScenarios();
            const applicationsPromise = apiApps.getApplications();
            const [exercises, scenarios, applications] = await Promise.all([exercisesPromise, scenariosPromise, applicationsPromise]);
            const scenarioGroups = Object.groupBy(scenarios ?? [], (s) => s._exercise.toString());
            set({ didInit: true, exercises, scenarios: scenarioGroups, applications });
        } catch (err: any) {
            set({ error: err });
        }
    },
    clearForm: () => {
        set({
            application: undefined,
            exercise: undefined,
            scenario: undefined,
            fields: undefined,
            template: undefined,
            edit: undefined,
            mode: undefined,
        });
    },
    loadExercise: async (applicationId: ObjectId, exercise?: xtypes.db.WithId<xtypes.apps.IExerciseInfo>, isEdit?: boolean) => {
        const apiPromises = [] as Promise<unknown>[];
        apiPromises.push(apiApps.getApplicationSchema(applicationId, 'exercise'));
        if (exercise) {
            apiPromises.push(apiApps.getExerciseTemplate(exercise._id));
        }
        const results = await Promise.all(apiPromises);
        const response = results[0] as xtypes.docs.IApplicationSchemaResponse | undefined;
        const template = results[1] as xtypes.docs.JSONNode | undefined;
        const edit = (isEdit === true) && (exercise?._organization != null); // ensure global exercise not updated
        const fields = (response?.schema == null) ? [] : Parser.fromSchema(response?.schema);
        set({ application: response?.application, exercise, fields, template, edit, mode: 'exercise' });
    },
    loadScenario: async (exercise: xtypes.db.WithId<xtypes.apps.IExerciseInfo>) => {
        const response = await apiApps.getApplicationSchema(exercise._application, 'scenario');
        const fields = (response?.schema == null) ? [] : Parser.fromSchema(response?.schema);
        set({ application: response?.application, exercise, fields, edit: false, mode: 'scenario' });
    },
    viewScenario: async (scenario: xtypes.db.WithId<xtypes.apps.IScenarioInfo>) => {
        const template = await apiApps.getScenarioTemplate(scenario._id);
        set({ template, mode: 'view' });
    },
    refreshExercises: async () => {
        const exercises = await apiApps.getExercises();
        set({ exercises });
    }
}));

import { useEffect, useRef, useState } from 'react'
import renderIf from '../../lib/renderIf';

interface PopupPosition {
    top: number;
    left: number;
}

interface PopupState {
    show: boolean;
    position: PopupPosition;
    onHide: () => void;
}

interface PopupHook extends PopupState {
    handle: (event: React.MouseEvent<HTMLElement>, targetKey: string) => void;
}

export function usePopup(): PopupHook {

    const [key, setKey] = useState('');
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const handle = (event: React.MouseEvent<HTMLElement>, targetKey: string) => {

        const rect = event.currentTarget.getBoundingClientRect();

        setPosition({ top: rect.bottom, left: event.clientX - 32 });

        if (targetKey === key) {
            setKey('');
        } else {
            setKey(targetKey);
        }

        event.stopPropagation();
    }

    const hide = () => setKey('');

    return { handle, onHide: hide, show: key !== '', position: position };
}

interface PopupProperties extends PopupState {
    children: React.ReactNode;
}

export function Popup(props: PopupProperties) {
    const menuRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        function hide() {
            if (menuRef.current) {
                menuRef.current.style.display = 'none';
                if (props.onHide) {
                    props.onHide();
                }
            }
        }
        function show() {
            if (menuRef.current) {
                menuRef.current.style.left = (props.position.left - 32).toString() + 'px';
                menuRef.current.style.top = props.position.top.toString() + 'px';
                menuRef.current.style.display = 'block';
            }
        }

        if (props.show) {
            show();
            document.onclick = (e) => {
                hide();
            };
        }
        return () => {
            document.onclick = null;
        }
    }, [props, menuRef]);

    return (
        <>
            {renderIf(
                props.show,
                <div style={{ position: 'absolute' }} ref={menuRef}>
                    {props.children}
                </div>
            )}
        </>
    );
}

import log from 'loglevel';
import * as api from './apiActions'
import * as xtypes from 'xtypes'

export async function doLogin(user: string, password: string): Promise<xtypes.auth.IAuthorization | undefined> {
    const credentials = {
        user,
        password
    }
    try {
        const response = await api.httpPost('AUTH LOGIN', api.url('api/id/auth'), credentials, true);
        return await response.json();
    } catch(err) {
        log.error(err)
    }
}

export async function doRefresh(): Promise<void> {
        await api.httpPut('AUTH REFRESH', api.url('api/id/auth'), undefined, true);
}

export async function doLogout(): Promise<boolean> {
    try {
        await api.httpDelete('AUTH LOGOUT', api.url('api/id/auth'), undefined, true);
        return true;
    } catch {
        return false;
    }
}



import classnames from 'classnames'
import renderIf from '../../lib/renderIf'
import { Spinner } from 'react-bootstrap'
import styles from './Backdrop.module.css'

export interface IBackdropProps {
    show?: boolean;
    text?: string;
    blur?: boolean;
    fullscreen?: boolean;
}

export default function Backdrop(props: IBackdropProps) {
    
    return (
        <>
            {renderIf(props.show ?? true,
                <div className={classnames(styles.backdrop, { [styles.blur]: props.blur }, { [styles.fullscreen]: props.fullscreen })} >
                    <div className={styles.spinner}>
                        <div>{props.text}</div>
                        <Spinner animation='border' variant='primary' />
                    </div>
                </div>
            )}
        </>
    );
}
import { FieldValues, RegisterOptions, SubmitHandler } from 'react-hook-form'

export type FormSubmitHandler = SubmitHandler<FieldValues>;

export type FieldType = 'string' | 'number' | 'boolean';
export type FormElement = 'text' | 'textarea' | 'number' | 'select' | 'option' | 'choice'; // $$$ range, email, password

export interface IFieldOption {
    label: string,
    value: any
}

export interface IFormField {
    id: string;
    label: string;
    type: FieldType;
    element: FormElement;
    description?: string;
    placeholder?: string;
    default?: any;
    options?: IFieldOption[];
    config?: RegisterOptions;
}

export type FormField = IFormField | IFormField[];

export function getField(field: FormField): IFormField {
    if (Array.isArray(field)) {
        return field[0];
    }
    return field;
}

export function getFormDefaults(fields: FormField[], template?: FieldValues): FieldValues {
    const defaultValues = {} as FieldValues;

    for (const field of fields) {
        // if (Array.isArray(field)) { continue; }
        defaultValues[getField(field).id] = getFieldDefault(field, template);
        // if (Array.isArray(field)) {
        //     const defaultValue = getDefaultValue(field[0], template);
        //     defaultValues[field[0].id] = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
        // } else {
        //     defaultValues[field.id] = getDefaultValue(field, template);
        // }
    }

    return defaultValues;
}

export function getFieldDefault(field: FormField, template?: FieldValues): any {
    // return getDefaultValue(getField(field), template);
    // console.log(getField(field).id + " FIELD DEFAULT1: " + Array.isArray(field));
    if (Array.isArray(field)) {
        const defaultValue = getDefaultValue(field[0], template);
        // console.log(getField(field).id + " FIELD DEFAULT2: " + Array.isArray(defaultValue));
        return Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    }
    // console.log(getField(field).id + " FIELD DEFAULT3");
    return getDefaultValue(field, template);
}

function getDefaultValue(field: IFormField, template?: FieldValues): any {
    return template != null ? template[field.id] : getEmptyFieldDefault(field);
}

function getEmptyFieldDefault(field: IFormField): any {
    if (field.default) {
        return field.default;
    }
    switch (field.type) {
        case 'string':
            return '';
        case 'number':
            return 1;
        case 'boolean':
            return false;
        default: // Don't throw - parser already validated
            break;
    }
}
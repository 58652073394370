import useInit from '../../hooks/init';
import renderIf from '../../lib/renderIf'
import Backdrop from '../../components/general/Backdrop'
import { Table } from 'react-bootstrap'
import { useAdminDirectoryStore } from '../../store/adminDirectoryStore'
import * as xtypes from 'xtypes'

export default function Users() {
    const stateDirectory = useAdminDirectoryStore();
    const init = useInit(stateDirectory);

    function toggleReset(user: xtypes.directory.IPrincipal): void {
        if (user.reset) {
            return;
        }
        user.reset = true;
        // state.setDelta(user);
    }

    function toggleDisabled(user: xtypes.directory.IPrincipal): void {
        user.disabled = !(user.disabled ?? false);
        // state.setDelta(user);
    }

    return (
        <>
            <div className='fs-3 fw-bold mb-3'>Team Members</div>
            {renderIf(init.ready(),
                <>
                    <Table bordered hover size='sm'>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Email</th>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Verified</th>
                                <th>Reset Password</th>
                                <th>Disabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stateDirectory.users.map((u, index) => (
                                <tr className='' key={index} /*onClick={() => onSelectTenant(t.id)}*/>
                                    <td>{u.name}</td>
                                    <td>{u.email}</td>
                                    <td>{u.display}</td>
                                    <td>{u.role}</td>
                                    <td><input type='checkbox' checked={u.verified ?? false} disabled={true} /></td>
                                    <td><input type='checkbox' checked={u.reset ?? false} disabled={u.reset ?? false} onChange={() => toggleReset(u)} /></td>
                                    <td><input type='checkbox' checked={u.disabled ?? false} onChange={() => toggleDisabled(u)} /></td>
                                </tr>))}
                        </tbody>
                    </Table>
                </>,
                <Backdrop text='Loading...' />)}
        </>
    );
}

import log from 'loglevel';

export class Microphone {

    private microphone: MediaRecorder | null | undefined = undefined;

    public async open(socket: WebSocket): Promise<void> {

        if (this.microphone != null) {
            return;
        }

        this.microphone = await this.getMicrophone();

        this.microphone.onstart = () => {
            log.debug("device: microphone opened");
            // resolve();
        };

        this.microphone.onstop = () => {
            log.debug("device: microphone closed");
            socket.close();
        };

        this.microphone.ondataavailable = (event) => {
            // log.debug(`device: microphone data received: ${event.data.size}`);
            if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
                socket.send(event.data);
            }
        };

        this.microphone.start(200);
    }

    public close() {
        if (this.microphone != null) {
            this.microphone.stop();
            this.microphone = null;
        }
    }

    private async getMicrophone(): Promise<MediaRecorder> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mimeType = 
                MediaRecorder.isTypeSupported("audio/webm") ?
                "audio/webm" :
                "audio/mp4";
            return new MediaRecorder(stream, { mimeType });
        } catch (error) {
            log.error("device: error accessing microphone:", error);
            throw error;
        }
    }
}
import { createContext, useContext } from 'react'

export interface AuthContext {
    isAuthenticated: () => boolean;
    hasRole: (role: string) => boolean;
    signin: (user: string, password: string) => Promise<boolean>;
    signout: () => Promise<void>;
}

export const Auth = createContext<AuthContext>(null!);

export function useAuth(): AuthContext {
    return useContext(Auth);
}
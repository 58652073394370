import { config } from '../config'
import log from 'loglevel';

export function url(path: string): URL {
    return new URL(`http://${document.location.hostname}:${config.app.PortAPI}/${path}`);
}

export async function json<T>(response: Response): Promise<T> {
    const raw = await response.text();
    const json = JSON.parse(
        raw,
        (key, value) => {
            if (key.startsWith('when')) {
                return new Date(value);
            }
            return value;
        });
    return json as T;
}

export async function httpGet(label: string, url: URL, credentials: boolean | undefined = undefined): Promise<Response> {
    log.info(`${label}: ${url}`);
    const options: RequestInit = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: credentials ? 'include' : undefined,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
        throw new Error(response.statusText)
    }
    return response;
}

export async function httpPost(label: string, url: URL, body: any, credentials: boolean | undefined = undefined): Promise<Response> {
    log.info(`${label}: ${url}`);
    const isPlainText = typeof body === 'string';
    const options: RequestInit = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': isPlainText ? 'text/plain' : 'application/json',
        },
        credentials: credentials ? 'include' : undefined,
        body: isPlainText ? body : body != null ? JSON.stringify(body) : undefined,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
        throw new Error(response.statusText)
    }
    return response;
}

export async function httpPatch(label: string, url: URL, body: any, credentials: boolean | undefined = undefined): Promise<Response> {
    log.info(`${label}: ${url}`);
    const isPlainText = typeof body === 'string';
    const options: RequestInit = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': isPlainText ? 'text/plain' : 'application/json',
        },
        credentials: credentials ? 'include' : undefined,
        body: isPlainText ? body : body != null ? JSON.stringify(body) : undefined,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
        throw new Error(response.statusText)
    }
    return response;
}

export async function httpPut(label: string, url: URL, body: any, credentials: boolean | undefined = undefined): Promise<Response> {
    log.info(`${label}: ${url}`);
    const isPlainText = typeof body === 'string';
    const options: RequestInit = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': isPlainText ? 'text/plain' : 'application/json',
        },
        credentials: credentials ? 'include' : undefined,
        body: isPlainText ? body : body != null ? JSON.stringify(body) : undefined,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
        throw new Error(response.statusText)
    }
    return response;
}

export async function httpDelete(label: string, url: URL, body: any | undefined = undefined, credentials: boolean | undefined = undefined): Promise<Response> {
    log.info(`${label}: ${url}`);
    const isPlainText = typeof body === 'string';
    const options: RequestInit = {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': isPlainText ? 'text/plain' : 'application/json',
        },
        credentials: credentials ? 'include' : undefined,
        body: isPlainText ? body : body != null ? JSON.stringify(body) : undefined,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
        throw new Error(response.statusText)
    }
    return response;
}

import { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { useAdminChatStore } from '../../store/adminChatStore'
import './Admin.css'

export default function Admin() {

    const state = useAdminChatStore();

    useEffect(() => {
        if (state.error != null) {
            setTimeout(() => state.clearError(), 2000);
        }
    }, [state.error, state])

    // function onSelectSession(id: string) {
    //     state.getChat(id);
    // }

    return (
        <>
            <Container className='floaterror mt-2'>
                <Alert variant='danger' dismissible show={state.error != null}>{state.error?.toString()}</Alert>
            </Container>
            <Button className='mt-3' variant='primary' onClick={() => state.getSessions()}>Refresh</Button>
            <Table className='mt-3' bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Session ID</th>
                        <th>Domain</th>
                        <th>Area</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {state.sessions.map((s, index) => (<tr className={state.sessionId === s.id ? 'session-selected' : ''} key={index} onClick={() => onSelectSession(s.id)}><td>{s.id}</td><td>{s.domain}</td><td>{s.area}</td></tr>))} */}
                </tbody>
            </Table>
            <Table className='mt-4 mb-5' bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Role</th>
                        <th>Message</th>
                    </tr>
                    {state.history.map((_, index) => (<tr key={index}><td>{state.history[state.history.length - index - 1].role}</td><td>{state.history[state.history.length - index - 1].content}</td></tr>))}
                </thead>
                <tbody>
                </tbody>
            </Table>
        </>
    );
}
import log from 'loglevel';

const themeKey = '__theme__';
const themeAuto = 'auto';
const themeDefault = 'light';

export const theme = {
    config: () => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
            log.debug('THEME CHANGE');
            switchColorScheme();
        });

        switchColorScheme();
    },

    get: (): string => {
        const themeName = localStorage.getItem(themeKey);
        return themeName ?? themeDefault;
    },

    set: (themeName: string) => {
        localStorage.setItem(themeKey, themeName);
        switchColorScheme();
    }
}

function switchColorScheme() {
    const themeName = theme.get();
    const themeActive =
        (themeName === themeAuto) ?
            window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light' :
            themeName;
    log.debug(`THEME: ${themeActive}`);
    document.querySelector('html')!.dataset.bsTheme = themeActive;
}

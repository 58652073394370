// general
export const home = '/';
export const sound = 'sound';

// auth
export const signin = 'signin';
export const activate = 'activate';

// settings: root
export const settings = 'settings';
// settings: user
export const profile = 'profile';
export const password = 'password';
// advanced settings
export const users = 'users';
export const userinvite = 'invite';
export const teams = 'teams';
export const training = 'training';
export const teamassign = 'assignments';
export const admin = 'admin';

// company info
export const us = 'us';
export const usabout = 'about';
export const uscareers = 'careers';
export const uscontact = 'contact';

// learning / training
export const evalhome = 'program'
export const chat = 'chat';

// landing
export const landing1 = 'try';
import { useEffect, useState } from 'react'
import { ObjectId } from 'bson'
import classNames from 'classnames';
import useInit from '../../hooks/init';
import renderIf from '../../lib/renderIf'
import Backdrop from '../../components/general/Backdrop'
import PopupTip from '../../components/general/PopupTip'
import { Popup, usePopup } from '../../components/general/Popup'
import { Button, ButtonGroup } from 'react-bootstrap'
import { CloseButton } from 'react-bootstrap'
import { DropdownButton } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import { Offcanvas } from 'react-bootstrap'
import { OverlayTrigger } from 'react-bootstrap'
import { Popover } from 'react-bootstrap'
import { ToggleButton } from 'react-bootstrap'
import * as CreateExercise from '../../components/apps/CreateExerciseForm'
import * as CreateScenario from '../../components/apps/CreateScenarioForm'
import { useAdminExerciseStore } from '../../store/adminExerciseStore'
import * as xtypes from 'xtypes'
import styles from './Training.module.css'

import * as api from '../../services/apiActions'

interface TrainingExerciseProps {
  exercise: xtypes.db.WithId<xtypes.apps.IExerciseInfo>;
  scenarios: xtypes.db.WithId<xtypes.apps.IScenarioInfo>[] | undefined;
  ready: boolean;
  expand?: boolean;
}

const processor = new xtypes.events.EventProcessor();
processor.hub.queue = async (obj: xtypes.events.QueueEvent) => console.log(`EVENT DISPATCH: ${JSON.stringify(obj, null, 2)}`);

export default function Training() {

  const [loading, setLoading] = useState(false);
  const [showScenarios, setShowScenarios] = useState(true);
  const stateExercises = useAdminExerciseStore();

  console.log(`TRAINING RENDER: ${stateExercises.application?.name ?? '<none>'}`);

  // const [queueEvent, setEvent] = useState<xtypes.events.QueueEvent | undefined>(undefined);

  useEffect(() => {
    console.log('EVENTS - INIT');
    const eventSource = new EventSource(api.url('api/events'));

    eventSource.onmessage = (event) => {
      const queueEvent = JSON.parse(event.data);
      if (xtypes.events.isTypedEvent(queueEvent)) {
        try {
          // setEvent(queueEvent);
          processor.dispatch(queueEvent);
        } catch (err) {
          console.error(err);
        }
      } else {
        console.error('ERROR: Unknown event');
      }
    };

    return () => {
      console.log('EVENTS - CLOSE');
      eventSource.close();
    }
  }, []);

  const init = useInit(stateExercises);
  const popupExercise = usePopup();
  const popupScenario = usePopup();

  function onClickExercise(
    event: React.MouseEvent<HTMLDivElement>,
    exercise: xtypes.db.WithId<xtypes.apps.IExerciseInfo>) {
    popupScenario.onHide();
    stateExercises.exercise = exercise;
    popupExercise.handle(event, exercise._id.toString());
  }

  function onClickScenario(
    event: React.MouseEvent<HTMLDivElement>,
    scenario: xtypes.db.WithId<xtypes.apps.IScenarioInfo>) {
    popupExercise.onHide();
    stateExercises.scenario = scenario;
    popupScenario.handle(event, scenario._id.toString());
  }

  function onShowFormFromExercise(isEdit?: boolean) {
    if (!stateExercises.exercise) {
      return;
    }
    popupExercise.onHide();
    setLoading(true);
    stateExercises.loadExercise(stateExercises.exercise._application, stateExercises.exercise, isEdit)
      .then(() => setLoading(false));
  }

  function onShowFormNewExercise(applicationId: ObjectId) {
    setLoading(true);
    stateExercises.exercise = undefined;
    stateExercises.loadExercise(applicationId)
      .then(() => setLoading(false));
  }

  function onShowFormNewScenario() {
    if (!stateExercises.exercise) {
      return;
    }
    popupExercise.onHide();
    setLoading(true);
    stateExercises.loadScenario(stateExercises.exercise)
      .then(() => setLoading(false));
  }

  function onShowFormViewScenario() {
    if (!stateExercises.scenario) {
      return;
    }
    popupExercise.onHide();
    setLoading(true);
    stateExercises.viewScenario(stateExercises.scenario)
      .then(() => setLoading(false));
  }

  async function onSubmitExercise(data: Record<string, unknown>) {
    setLoading(true);
    const applicationId = stateExercises.application?._id;
    const template = stateExercises.edit ? stateExercises.template : undefined;
    const exercise = stateExercises.edit ? stateExercises.exercise : undefined;
    stateExercises.clearForm();
    let didUpdate = false;
    if (stateExercises.edit === true) {
      didUpdate = await CreateExercise.UpdateExercise(data, template!, exercise!);
    } else {
      await CreateExercise.CreateExercise(data, applicationId!);
      didUpdate = true;
    }
    if (didUpdate) {
      stateExercises.refreshExercises()
        .then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }

  async function onSubmitScenario(data: Record<string, unknown>) {
    setLoading(true);
    const exercise = stateExercises.exercise!;
    stateExercises.clearForm();
    await CreateScenario.CreateScenario(data, exercise._id);
    stateExercises.refreshExercises()
      .then(() => setLoading(false));
  }

  function onHideForm() {
    stateExercises.clearForm();
  }

  const ToolBar = () => (
    <div className='d-grid gap-2 d-md-flex justify-content-md-end align-items-center'>
      <PopupTip text={`Toggle to ${showScenarios ? 'hide' : 'show'} exercise scenarios`}>
        <ToggleButton
          size='sm'
          id='toggleView'
          type='checkbox'
          disabled={!init.ready()}
          variant={showScenarios ? 'primary' : 'outline-primary'}
          checked={showScenarios}
          value={1}
          onChange={() => setShowScenarios(!showScenarios)}
        >
          Scenarios
        </ToggleButton>
      </PopupTip>
      <PopupTip text='Reload the latest data'>
        <Button
          size='sm'
          disabled={!init.ready()}
          onClick={() => init.refresh()}>
          Refresh
        </Button>
      </PopupTip>
      <PopupTip text='Select an application to create a new exercise'>
        <DropdownButton title='New Exercise' size='sm' disabled={!init.ready()}>
          {(stateExercises.applications ?? [])?.map(
            (application, index) =>
              <OverlayTrigger
                key={`app${index}`}
                placement='left'
                delay={{ show: 500, hide: 0 }}
                show={application.description == null ? false : undefined}
                overlay={<Popover body={true}>{application.description}</Popover>}
              >
                <Dropdown.Item onClick={() => onShowFormNewExercise(application._id)}>
                  {application.display}
                </Dropdown.Item>
              </OverlayTrigger>
          )}
        </DropdownButton>
      </PopupTip>
    </div>
  );

  const TrainingExercise = (props: TrainingExerciseProps) => {
    return (
      <div className={classNames(styles.exercise, 'row', 'mb-2')}>
        <div
          className='action row align-items-center fw-bold'
          onClick={e => onClickExercise(e, props.exercise)}
        >
          <div className='col-5 text-truncate ms-0 ps-0'>{props.exercise.name}</div>
          <div className='col-5 text-truncate'>{props.exercise.application}</div>
        </div>
        {renderIf(props.expand === true,
          <div className='row ps-4 pb-1'>
            {renderIf(props.scenarios != null,
              <div className='col-8'>
                {props.scenarios?.map((scenario, index) =>
                  <div className='action p-0' key={`${props.exercise._id}${index}`} onClick={e => onClickScenario(e, scenario)}>
                    {scenario.name}
                  </div>)}
              </div>,
              <div className='col-8 text-secondary'>None</div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <Popup {...popupExercise}>
        <ButtonGroup size='sm' vertical={true} >
          <Button onClick={() => onShowFormFromExercise(true)}>
            Edit Exercise
          </Button>
          <Button onClick={() => onShowFormFromExercise()}>
            Clone Exercise
          </Button>
          <Button onClick={() => onShowFormNewScenario()}>
            New Scenario
          </Button>
        </ButtonGroup>
      </Popup>
      <Popup {...popupScenario}>
        <ButtonGroup size='sm' vertical={true} >
          <Button onClick={() => onShowFormViewScenario()}>
            View Scenario
          </Button>
          {/* <Button onClick={() => onShowFormViewScenario()}>
            Assign Scenario
          </Button> */}
          <Button onClick={() => onShowFormViewScenario()}>{/* $$$ ENABLE / DISABLE */}
            Disable Scenario
          </Button>
        </ButtonGroup>
      </Popup>
      <div className='d-flex flex-row justify-content-between mb-3'>
        <div className='fs-3 fw-bold'>Training</div>
        <ToolBar />
      </div>
      {renderIf(init.ready(),
        <>
          <div className='container p-0 mt-2 mb-2'> {/* SCROLLABLE */}
            <div className='row fw-bold'>
              <div className='col-5'>Exercise</div>
              <div className='col-5'>Application</div>
            </div>
            {
              stateExercises.exercises?.map(
                (exercise, index) =>
                  <TrainingExercise
                    key={`ex${index}`}
                    exercise={exercise}
                    scenarios={(stateExercises.scenarios ?? {})[exercise._id.toString()]}
                    ready={init.ready()}
                    expand={showScenarios} />
              )
            }
          </div>
          <Offcanvas show={stateExercises.application != null} placement='end' onHide={() => onHideForm()}>
            <div className='overflow-y-scroll'>
              <div className='border-bottom border-primary'>
                <div className='d-flex flex-row h4 fw-bold ps-1 bg-primary justify-content-between align-items-center'>
                  New Exercise
                  <CloseButton onClick={() => onHideForm()} />
                </div>
                <div className='container mb-2'>
                  <div className='form-label fw-bold text-primary-emphasis'>Application:</div>
                  <div className='h4'>{stateExercises.application?.display}</div>
                  <div className='fs-small'>{stateExercises.application?.description}</div>
                </div>
              </div>
              <div className='container mt-1 overflow-y-auto'>
                {renderIf(stateExercises.mode === 'exercise',
                  <CreateExercise.Form
                    application={stateExercises.application}
                    exercise={stateExercises.exercise}
                    fields={stateExercises.fields ?? []}
                    template={stateExercises.template}
                    onSubmit={onSubmitExercise} />,
                  <CreateScenario.Form
                    application={stateExercises.application}
                    exercise={stateExercises.exercise}
                    fields={stateExercises.fields ?? []}
                    template={stateExercises.template}
                    onSubmit={onSubmitScenario} />
                )}
              </div>
            </div>
          </Offcanvas >
          <Offcanvas
            className='h-100'
            show={stateExercises.mode === 'view' && stateExercises.template != null}
            placement='bottom'
            as='div'
            onHide={() => onHideForm()}>
            <div className='d-flex flex-row h4 fw-bold ps-1 bg-primary justify-content-between align-items-center'>
              View Scenario
              <CloseButton onClick={() => onHideForm()} />
            </div>
            <div>
              <div className='border-bottom border-primary'>
                <div className='ms-2 me-2'>
                  {/* <div className='form-label fw-bold text-primary-emphasis'>Application:</div>
                <div className='h4'>{stateExercises.application?.display}</div>
                <div className='fs-small'>{stateExercises.application?.description}</div> */}
                  <div className='form-label fw-bold text-primary-emphasis'>Exercise:</div>
                  <div className='h4'>{stateExercises.scenario?.exercise}</div>
                  <div className='fs-small'>{stateExercises.scenario?.description}</div>
                </div>
              </div>
            </div>
            <div className='ms-2 me-2'>
              <pre>{JSON.stringify(stateExercises.template, null, 4)}</pre>
            </div>
          </Offcanvas>
        </>
      )
      }
      <Backdrop text='Loading...' show={!init.ready() || loading} />
    </>
  );
}

import { useState } from 'react'
import useInit from '../../hooks/init';
import { useNavigate } from 'react-router-dom'
import { ObjectId } from 'bson'
import * as AppPaths from '../../appPaths'
import * as promise from '../../lib/promise'
import renderIf from '../../lib/renderIf'
import { Button } from 'react-bootstrap'
import Backdrop from '../../components/general/Backdrop'
import ConfirmModal from '../../components/general/ConfirmModal'
import SelfHeader from '../../components/apps/SelfHeader'
import AssignmentList from '../../components/apps/AssignmentList'
import CompletedList from '../../components/apps/CompletedList'
import RecentList from '../../components/apps/RecentList'
import ScenarioList from '../../components/apps/ScenarioList'
import { useProfileStore } from '../../store/profileStore'
import { useMemberStore } from '../../store/memberStore'
import { useChatStore } from '../../store/chatStore'
import * as xtypes from 'xtypes'

interface IConfirmAction {
    title: string;
    content: string | JSX.Element;
    callback: () => Promise<void>;
}

export default function Activity() {
    const stateProfile = useProfileStore();
    const stateMember = useMemberStore();
    const stateChat = useChatStore();
    const [confirm, setConfirm] = useState<IConfirmAction | undefined>();
    const [working, setWorking] = useState(false);
    const navigate = useNavigate();

    const init = useInit(stateProfile, stateMember);

    function onDisplayCompleted(session: xtypes.db.WithId<xtypes.apps.ISessionInfo>) {
        console.log(session);
        alert(session._id);
    }

    function onConfirm(confirmation: boolean) {
        if (confirmation) {
            setWorking(true);
            confirm?.callback();
        }
        setConfirm(undefined);
    }

    function onLaunchActive(session: xtypes.db.WithId<xtypes.apps.ISessionInfo>) {
        setConfirm({
            callback: onStart,
            title: 'Resume Session',
            content:
                <>
                    <div className='fs-5'>Continue with previous session?</div>
                    <div className='p-4'>
                        <div><span className='fw-bold'>Scenario:</span>{session.scenario}</div>
                        <div><span className='fw-bold'>Execise:</span>{session.exercise}</div>
                        <div><span className='fw-bold'>Started On:</span>{session.whenstarted.toLocaleDateString()}</div>
                    </div>
                </>
        });

        function onStart(): Promise<void> {
            StartSession(session._id);
            return promise.empty();
        }
    }

    function onLaunchAssignment(assigment: xtypes.db.WithId<xtypes.apps.IAssignmentInfo>) {
        setConfirm({
            callback: onStart,
            title: 'Launch Assignment',
            content:
                <>
                    <div className='fs-5'>Begin assigned scenario?</div>
                    <div className='p-4'>
                        <div><span className='fw-bold'>Scenario:</span>{assigment.scenario}</div>
                        <div><span className='fw-bold'>Execise:</span>{assigment.exercise}</div>
                        <div><span className='fw-bold'>Assigned On:</span>{assigment.whenassigned.toLocaleDateString()}</div>
                    </div>
                </>
        });

        function onStart(): Promise<void> {
            return CreateSession(assigment._scenario, assigment._id);
        }
    }

    function onLaunchScenario(scenario: xtypes.db.WithId<xtypes.apps.IScenarioInfo>) {
        setConfirm({
            callback: onStart,
            title: 'Launch Scenario',
            content:
                <>
                    <div className='fs-5'>Begin selected scenario?</div>
                    <div className='p-4'>
                        <div><span className='fw-bold'>Scenario:</span>{scenario.name}</div>
                        <div><span className='fw-bold'>Execise:</span>{scenario.exercise}</div>
                    </div>
                </>

        });

        function onStart(): Promise<void> {
            return CreateSession(scenario._id);
        }
    }

    async function CreateSession(scenario: ObjectId, assignment: ObjectId | undefined = undefined): Promise<void> {
        await stateChat.newSession({ scenario, assignment });
        stateMember.getSessions();
        navigate(AppPaths.chat, {})
    }

    function StartSession(session: ObjectId): void {
        stateChat.setSession(session);
        navigate(AppPaths.chat, {})
    }

    return (
        <>
            {/* REFRESH */}
            {/* MESSAGES */}
            {renderIf(init.ready() && !working,
                <>
                    <div className='d-flex flex-row justify-content-between mt-2 mb-3'>
                        <SelfHeader info={stateMember.self} principal={stateProfile.principal} />
                        <div className='d-grid gap-2 d-md-flex justify-content-md-end align-items-center'>
                            <div className='d-flex flex-column'>
                                <Button size='sm' disabled={!init.ready()} onClick={() => init.refresh()}>Refresh</Button>
                            </div>
                        </div>
                    </div>
                    {/* <div className='mt-3 mb-2'>
                        <SelfHeader info={stateMember.self} principal={stateProfile.principal} />
                    </div> */}
                    <div className='container'>
                        <div className='row g-3'>
                            <div className='col-lg-6 col-md-12'>
                                <div className='h-100 p-2 border border-secondary-subtle'>
                                    <AssignmentList assignments={stateMember.assignments} onSelect={onLaunchAssignment} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className='h-100 p-2 border border-secondary-subtle'>
                                    <ScenarioList scenarios={stateMember.scenarios} onSelect={onLaunchScenario} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='h-100 p-2 border border-secondary-subtle'>
                                    <RecentList sessions={stateMember.sessions} onSelect={onLaunchActive} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='h-100 p-2 border border-secondary-subtle'>
                                    <CompletedList sessions={stateMember.sessions} onSelect={onDisplayCompleted} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>,
                <Backdrop text={working ? 'Starting...' : 'Loading...'} />
            )}
            <ConfirmModal show={confirm != null} title={confirm?.title ?? ''} content={confirm?.content ?? ''} onChoice={onConfirm} />
        </>
    );
}

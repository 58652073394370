import { ReactNode } from "react";
import { FieldErrors, FieldValues, FormProvider, useForm } from "react-hook-form";
import * as dynamic from "./DynamicTypes";

interface FormProps {
    children: ReactNode;
    defaultValues: FieldValues;
    onSubmit: dynamic.FormSubmitHandler;
}

export default function DynamicForm(props: FormProps) {

    // console.log('DYNAMIC FORM RENDER');
    console.log('DEFAULTS:');
    console.log(props.defaultValues);
    const formMethods = useForm({
        defaultValues: props.defaultValues
    });
    const { handleSubmit, reset } = formMethods;

    function onInvalid(errors: FieldErrors<FieldValues>) {
        console.log('ERRORS');
        console.log(errors);
    }

    return (
        <form onSubmit={handleSubmit(props.onSubmit, onInvalid)}>
            <FormProvider {...formMethods}>
                {props.children}
            </FormProvider>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <button
                    className='btn btn-outline-secondary'
                    type='reset'
                    tabIndex={0}
                    onClick={() => reset()}
                >
                    Reset
                </button>
                <button
                    className='btn btn-primary'
                    type='submit'
                    tabIndex={0}
                >
                    Submit
                </button>
            </div>
        </form>
    );
}
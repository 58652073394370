import log from 'loglevel';
import { ObjectId } from 'bson'
import * as api from './apiActions'
import * as xtypes from 'xtypes'

export async function getTeams(): Promise<xtypes.db.WithId<xtypes.apps.ITeamInfo>[] | undefined> {
    try {
        const response = await api.httpGet('GET TEAMS', api.url('api/team'), true);
        return await api.json<xtypes.db.WithId<xtypes.apps.ITeamInfo>[]>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getTeamMembers(): Promise<xtypes.db.WithId<xtypes.apps.ITeamMember>[] | undefined> {
    try {
        const response = await api.httpGet('GET TEAMMEMBERS', api.url('api/member'), true);
        return await api.json<xtypes.db.WithId<xtypes.apps.ITeamMember>[]>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getSelf(): Promise<xtypes.db.WithId<xtypes.apps.ITeamMemberInfo> | undefined> {
    try {
        const response = await api.httpGet('GET SELF', api.url('api/self'), true);
        return await api.json<xtypes.db.WithId<xtypes.apps.ITeamMemberInfo>>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getOrganization(): Promise<xtypes.db.WithId<xtypes.apps.IOrganizationInfo> | undefined> {
    try {
        const response = await api.httpGet('GET ORGANIZATION', api.url('api/organization'), true);
        return await api.json<xtypes.db.WithId<xtypes.apps.IOrganizationInfo>>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getApplications(): Promise<xtypes.db.WithId<xtypes.apps.IApplicationInfo>[] | undefined> {
    try {
        const response = await api.httpGet('GET APPLICATIONS', api.url('api/app'), true);
        return await api.json<xtypes.db.WithId<xtypes.apps.IApplicationInfo>[]>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getExercises(): Promise<xtypes.db.WithId<xtypes.apps.IExerciseInfo>[] | undefined> {
    try {
        const response = await api.httpGet('GET EXERCISES', api.url('api/exercise'), true);
        return await api.json<xtypes.db.WithId<xtypes.apps.IExerciseInfo>[]>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getScenarios(): Promise<xtypes.db.WithId<xtypes.apps.IScenarioInfo>[] | undefined> {
    try {
        const response = await api.httpGet('GET SCENARIOS', api.url('api/scenario'), true);
        return await api.json<xtypes.db.WithId<xtypes.apps.IScenarioInfo>[]>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getApplicationSchema(appId: ObjectId, formType: 'exercise' | 'scenario'): Promise<xtypes.docs.IApplicationSchemaResponse | undefined> {
    try {
        const response = await api.httpGet('GET APPLICATION TEMPLATE', api.url(`api/app/${appId}/${formType}`), true);
        return await api.json<xtypes.docs.IApplicationSchemaResponse>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function createExercise(request: xtypes.docs.IExerciseTemplateRequest): Promise<void> {
    try {
        await api.httpPost('POST EXERCISE', api.url('api/exercise'), request, true);
    } catch (err) {
        log.error(err);
    }
}

export async function createScenario(request: xtypes.docs.IScenarioGeneratorRequest): Promise<void> {
    try {
        await api.httpPost('POST SCENARIO', api.url('api/scenario'), request, true);
    } catch (err) {
        log.error(err);
    }
}

export async function updateExercise(exerciseId: ObjectId, exerciseDelta: Partial<xtypes.apps.IExercise>): Promise<void> {
    try {
        await api.httpPatch('PATCH EXERCISE', api.url(`api/exercise/${exerciseId}`), exerciseDelta, true);
    } catch (err) {
        log.error(err);
    }
}

export async function getExerciseTemplate(exerciseId: ObjectId): Promise<xtypes.docs.JSONNode | undefined> {
    try {
        const response = await api.httpGet('GET EXERCISE TEMPLATE', api.url(`api/exercise/${exerciseId}/template`), true);
        return await api.json<xtypes.docs.JSONNode>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function getScenarioTemplate(scenarioId: ObjectId): Promise<xtypes.docs.JSONNode | undefined> {
    try {
        const response = await api.httpGet('GET SCENARIO TEMPLATE', api.url(`api/scenario/${scenarioId}/template`), true);
        return await api.json<xtypes.docs.JSONNode>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function updateExerciseTemplate(exerciseId: ObjectId, template: xtypes.docs.JSONNode): Promise<void> {
    try {
        await api.httpPut('PUT EXERCISE TEMPLATE', api.url(`api/exercise/${exerciseId}/template`), template, true);
    } catch (err) {
        log.error(err);
    }
}

export async function addExerciseTeam(exerciseId: ObjectId, teamId: ObjectId): Promise<void> {
    try {
        await api.httpPost('POST EXERCISE TEAM', api.url(`api/exercise/${exerciseId}/team/${teamId}`), true);
    } catch (err) {
        log.error(err);
    }
}

export async function removeExerciseTeam(exerciseId: ObjectId, teamId: ObjectId): Promise<void> {
    try {
        await api.httpDelete('DELETE EXERCISE TEAM', api.url(`api/exercise/${exerciseId}/team/${teamId}`), true);
    } catch (err) {
        log.error(err);
    }
}


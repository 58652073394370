import renderIf from '../../lib/renderIf'
import ContentList from '../general/ContentList'
import * as xtypes from 'xtypes'

export interface RecentListProps {
    sessions: xtypes.db.WithId<xtypes.apps.ISessionInfo>[];
    onSelect?: (session: xtypes.db.WithId<xtypes.apps.ISessionInfo>) => void;
}

export default function RecentList(props: RecentListProps) {

    const sessions = props.sessions.filter(session => session.whencompleted == null);

    return (
        <ContentList title='Active'>
            {renderIf(sessions.length === 0,
                <div className='text-secondary'>None</div>,
                <div className='container p-0 mt-2 mb-2'> {/* SCROLLABLE */}
                    {sessions.map((session, index) =>
                        <div
                            className='action row'
                            key={`ses-${index}`}
                            onClick={() => props.onSelect?.call(null, session)}
                        >
                            <div className='col text-truncate'>{session.scenario}</div>
                            <div className='col text-truncate'>{session.exercise}</div>
                            <div className='col-3'>{session.whenstarted.toLocaleDateString()}</div>
                        </div>
                    )}
                </div>
            )}
        </ContentList >
    );
};

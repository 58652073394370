import { create } from 'zustand'
import * as api from '../services/apiDirectory'

export interface ActivationState {
    ticket: string,
    isValid: boolean,
    verifyTicket: (ticket: string) => Promise<boolean>;
}

export const useActivateStore = create<ActivationState>((set) => ({
    ticket: '',
    isValid: false,
    didInit: false,
    verifyTicket: async (ticket: string) => {
        const isValid = await api.validateInvite(ticket);
        set({ ticket, isValid });
        return isValid;
    },
}));

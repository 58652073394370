import * as xtypes from 'xtypes'

export interface SelfHeaderProps {
    principal: xtypes.directory.IPrincipal | undefined;
    info: xtypes.apps.ITeamMemberInfo | undefined;
}

export default function SelfHeader(props: SelfHeaderProps) {
    return (
        <div className={'d-flex flex-row justify-content-center fs-4 fw-bold'}>
            {props.principal?.display}&nbsp;@&nbsp;{props.info?.organization}
        </div>
    );
};

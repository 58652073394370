import log from 'loglevel';

export class AudioPlayer {

    private audio: HTMLAudioElement | undefined = undefined;

    public play(url: string) {
        if (this.audio != null) {
            this.stop();
        }

        this.audio = new Audio(url);

        this.audio.play();
        log.debug('Audio: started');

        this.audio.addEventListener('ended', () => {
            log.debug('Audio: finished');
            this.stop();
        });
    }

    public stop() {
        if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
            log.debug('Audio: stopped');
        }
    }
}
import { NavLink, Outlet } from 'react-router-dom'
import * as AppPaths from '../appPaths'
import '../styles/nav.css'
// import styles from './Company.module.css'

export default function Company() {
    return (
        <div className='nav-container'>
            <div className='nav-categories'>
                <ul>
                    <li><NavLink to={AppPaths.usabout}>About</NavLink></li>
                    <li><NavLink to={AppPaths.uscontact}>Contact</NavLink></li> 
                    <li><NavLink to={AppPaths.uscareers}>Careers</NavLink></li> 
                </ul>
            </div>
            <div className='nav-content'>
                <Outlet />
            </div>
        </div>
    );
}

import { create } from 'zustand'
import * as xtypes from 'xtypes'
// import * as api from '../services/apiChat'

export interface AdminChatState {
    sessions: xtypes.apps.ISessionInfo[];
    sessionId: string | undefined;
    history: xtypes.apps.ISessionMessage[];
    error: any | undefined,
    clearError: () => void;
    getSessions: () => void;
    setSession: (id: string) => void;
    addMessage: (id: string, text: string) => void;
    getChat: (id: string) => void;
}

export const useAdminChatStore = create<AdminChatState>((set) => ({
    sessions: [],
    sessionId: undefined,
    history: [],
    error: undefined,
    clearError: () => set({ error: undefined }),
    getSessions: async () => {
        // try {
        //     const sessions = await api.getSessions();
        //     set({ sessions });
        // } catch (err: any) {
        //     set({ error: err });
        // }
    },
    setSession: (id) => {
        set({ sessionId: id });
    },
    addMessage: async (id, text) => {
        // const input: xtypes.chat.ChatMessage = {
        //     id,
        //     text,
        //     role: xtypes.chat.ChatRole.Input,
        // }
        // try {
        //     const response = await api.addMessage(input);
        //     set((state) => ({ history: [...state.history, input, response] }));
        // } catch (err: any) {
        //     set({ error: err });
        // }
    },
    getChat: async (id: string) => {
        // try {
        //     const history = await api.getMessages(id);
        //     set({ sessionId: id, history });
        // } catch (err: any) {
        //     set({ error: err });
        // }
    }
}));

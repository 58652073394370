import { Outlet } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import logo from '../assets/notebook-exam.svg'
import styles from './Landing.module.css'

export default function Landing() {

    return (
        <div className='root'>
            <div className={`brand ${styles.brand}`}>
                <img src={logo} alt='logo' />
                <span>FaciliBus AI</span>
            </div>
            <div className='root-content'>
                <Container className='root-control'>
                    <Outlet />
                </Container>
            </div>
        </div >
    );
}

import { OverlayTrigger } from 'react-bootstrap'
import { Tooltip } from 'react-bootstrap'

interface PopupTipProps {
    text: string;
    children: React.ReactElement;
}

export default function PopupTip(props: PopupTipProps) {
    return (
        <OverlayTrigger
            placement='top'
            delay={{ show: 1500, hide: 100 }}
            overlay={<Tooltip>{props.text}</Tooltip>}
        >
            {props.children}
        </OverlayTrigger>
    );
}

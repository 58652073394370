
export function same(value1: any, value2: any): boolean {

    const isObject = isValueObject(value1);
    if (isObject !== isValueObject(value2)) {
        return false;
    }

    if (isObject) {
        return sameObject(value1, value2);
    }

    return value1 === value2;
}

export function sameValue<T>(value: T, base: T | undefined) {
    return base && value === base;
}

export function sameObject(object1: Record<string, any>, object2: Record<string, any> | undefined) {

    if (!object2) {
        return false;
    }

    const keys = Object.keys(object1);

    if (keys.length !== Object.keys(object2).length) return false;

    for (const key of keys) {
        const value1 = object1[key];
        const value2 = object2[key];

        const isObject = isValueObject(value1);
        if (isObject !== isValueObject(value2)) {
            return false;
        }

        if ((isObject && !sameObject(value1, value2)) ||
            (!isObject && value1 !== value2)) {
            return false;
        }
    }

    return true;
};

function isValueObject(value: any): boolean {
    return value != null && typeof value === "object";
};

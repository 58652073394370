import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Auth, useAuth } from '../hooks/auth'
import { home, signin } from '../appPaths'
import * as api from '../services/apiAuth'
import * as xtypes from 'xtypes'

// const tokenExp = new RegExp('(?:(?:^|.*;\s*)' + xtypes.auth.AuthTokenKey + '\s*=\s*([^;]*).*$)|^.*$');
const tokenExp = new RegExp('(?:(?:^|.*;\\s*)xauth=([^;]*).*$)|^.*$');
const expiryExp = new RegExp('[,;\\s]xexpire=([^\\s,;]*)');

export interface RequireAuthProps {
    children: JSX.Element;
    role?: string | undefined;
}

export function RequireAuth(props: RequireAuthProps) {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.isAuthenticated()) {
        return <Navigate to={signin} state={{ from: location }} replace />;
    }

    if (props.role != null) {
        if (!auth.hasRole(props.role)) {
            return <Navigate to={home} replace />;
        }
    }

    return props.children;
}

export interface AuthProviderProps {
    children: JSX.Element;
    threshold: number;
}

export function AuthProvider(props: AuthProviderProps) {

    const [roles, setRoles] = useState<Record<string, boolean>>(loadRoles());
    const [expiry, setExpiry] = useState(getExpiryRemainder());

    // console.log(`AUTH: INIT ${expiry}`);

    const isAuthenticated = (): boolean => {
        // all valid tokens must include an expiry and role definition
        if (expiry === 0 || Object.keys(roles).length === 0) {
            return false;
        }
        // ensure token present in cookie
        const token = getTokenCookie();
        return token != null;
    }

    const hasRole = (role: string): boolean => {
        if (expiry <= 0) {
            return false;
        }
        return roles[role] ?? false;
    }

    const signin = async (username: string, password: string): Promise<boolean> => {
        const authorization = await api.doLogin(username, password);
        if (authorization != null && authorization.token === getTokenCookie()) {
            setRoles(saveRoles(authorization));
            setExpiry(getExpiryRemainder());
            return true;
        }
        return false;
    };

    const signout = async (): Promise<void> => {
        const token = getTokenCookie();
        if (token != null) {
            await api.doLogout();
            sessionStorage.removeItem(token);
            setExpiry(0);
            setRoles({});
        }
    };

    useEffect(() => {
        // console.log('AUTH: REFRESH INIT');
        function doRefresh() {
            // expiry may have extened due to api calls
            const currentExpiry = getExpiryRemainder();
            if (expiry - props.threshold > 0) {
                setExpiry(currentExpiry);
                return;
            }
            // otherwise, explicit refresh
            api.doRefresh()
                .then(() => {
                    // console.log('AUTH: REFRESH!!!');
                    setExpiry(getExpiryRemainder());
                })
                .catch(_ => {
                    // console.log('AUTH: REFRESH FAILURE');
                    signout();
                });
        }
        
        if (expiry === 0) {
            return;
        }
        const remaining = expiry - props.threshold
        if (remaining <= 0) {
            doRefresh();
            return;
        }
        console.log(`AUTH: REFRESH TIMER ${remaining}`);
        const timeoutId = setTimeout(() => {
            doRefresh();
        }, remaining);

        return () => {
            if (timeoutId) {
                // console.log('AUTH: REFRESH CLEAR');
                clearTimeout(timeoutId)
            }
        };
    }, [props.threshold, expiry]);

    const value = { isAuthenticated, hasRole, signin, signout };

    return <Auth.Provider value={value}>{props.children}</Auth.Provider>;
}

function getExpiryRemainder(): number {
    const target = getExpiryTarget();
    return Math.max(0, target - Date.now());
}

function getExpiryTarget(): number {
    const match = document.cookie.match(expiryExp);
    const value = match != null && match.length > 1 ? match[1] : undefined;
    if (!value) {
        return 0;
    }
    const expireTarget = new Date(decodeURIComponent(value));
    return expireTarget.getTime();
}

function getTokenCookie(): string | undefined {
    const match = document.cookie.match(tokenExp);
    if (match != null && match.length > 1) {
        return match[1];
    }
}

function loadRoles(): Record<string, boolean> {
    const token = getTokenCookie();
    if (token == null) {
        return {};
    }

    const json = sessionStorage.getItem(token);
    if (json == null) {
        return {};
    }
    return JSON.parse(json);
}

function saveRoles(authorization: xtypes.auth.IAuthorization): Record<string, boolean> {
    const roleMap: Record<string, boolean> = {};
    for (const role of authorization.roles) {
        roleMap[role] = true;
    }
    sessionStorage.setItem(authorization.token, JSON.stringify(roleMap));
    return roleMap;
}

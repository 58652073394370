import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { ReactComponent as Icon } from '../../assets/handshake.svg'
import styles from './Company.module.css'

export default function Contact() {
  return (
    <>
      <div className={styles.image}><Icon /></div>
      <div className={styles.title}>We're Here for You</div>
      <div className={styles.content}>
        <div>Text</div>
        <div>Text</div>
      </div>
      <div className={styles.form}>
        <Form>
          <Form.Group>
            <Form.Label className='fw-bold' htmlFor='contactname'>Name:</Form.Label>
          </Form.Group>
          {/*ref={inputRef}*/}
          <Form.Control id='contactname' tabIndex={0} />
          <Form.Group>
            <Form.Label className='fw-bold' htmlFor='contactemail'>Email:</Form.Label>
            <Form.Control id='contactemail' type='email' tabIndex={0} />
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold' htmlFor='contactphone'>Phone:</Form.Label>
            <Form.Control id='contactphone' type='phone' tabIndex={0} placeholder='' />
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold' htmlFor='contactphone'>Note:</Form.Label>
            <Form.Control id='contactnote' as="textarea" rows={3} tabIndex={0} placeholder='Optional...' />
          </Form.Group>
          <div className='landing-form-action'>
            <Button variant='primary'>Contact Us</Button>
          </div>
        </Form>
      </div>
    </>);
}

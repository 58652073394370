import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ToggleButton } from 'react-bootstrap'
import { Stack } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { AudioPlayer } from '../../services/AudioPlayer'
import { Microphone } from '../../services/Microphone'
import { Speaker } from '../../services/Speaker'
import { Transcriber } from '../../services/Transcriber'
import './Sound.css'

const microphone = new Microphone();
const transcriber = new Transcriber();
const player = new AudioPlayer();
const speaker = new Speaker();
const speechModel = 'aura-asteria-en';

export default function Chat() {
    const [isListening, toggleListening] = useState(false);
    const [transcriptValue, setTranscript] = useState('');

    useEffect(() => {
        function appendTranscript(text: string) {
            setTranscript(transcriptValue + ' ' + text);
        }

        transcriber.attach(appendTranscript);

        return () => {
            transcriber.detach();
        };
    }, [transcriptValue]);

    const handleToggleListening = async (checked: boolean) => {
        try {
            if (checked) {
                const socket = transcriber.open();
                await microphone.open(socket);
            } else {
                microphone.close();
            }
            toggleListening(checked);
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };

    const handleSpeak = async (text: string): Promise<void> => {
        try {
            // speaker.generate(text, speechModel);
            speaker.generate(text, speechModel, (url) => player.play(url));
            // player.play(url);
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }

    return (
        <>
            <div className='mt-3 mb-4'>
                <Stack>
                    <ToggleButton
                        id='toggle-listen'
                        type='checkbox'
                        variant={isListening ? 'danger' : 'primary'}
                        checked={isListening}
                        value={0}
                        onChange={async (e) => handleToggleListening(e.currentTarget.checked)}>
                        {isListening ? 'Pause' : 'Listen'}
                    </ToggleButton>
                </Stack>
            </div>
            <div>
                <Form>
                    <Form.Group>
                        <Form.Label htmlFor='voiceInput'>Transcription:</Form.Label>
                        <Form.Control
                            as='textarea'
                            id='voiceInput'
                            rows={3}
                            readOnly={isListening}
                            aria-describedby='voiceInputHelp'
                            placeholder='What you say will be displayed here.'
                            onChange={(e) => setTranscript(e.currentTarget.value)}
                            value={transcriptValue} />
                    </Form.Group>
                    <Form.Text id='voiceInputHelp' muted>
                        Click on the 'Listen' button.
                    </Form.Text>
                </Form>
            </div>
            <div className='Controls mt-3'>
                <Stack>
                    <ToggleButton
                        id='command-clear'
                        variant={isListening ? 'secondary' : 'info'}
                        disabled={isListening}
                        value={1}
                        onClick={(e) => setTranscript('')}>
                        Clear
                    </ToggleButton>
                    <Button
                        className='mt-1'
                        id='command-speak'
                        variant={transcriptValue.length > 0 ? 'success' : 'info'}
                        disabled={transcriptValue.length === 0}
                        value={2}
                        onClick={(e) => handleSpeak(transcriptValue)}>
                        Speak
                    </Button>
                </Stack>
            </div>
        </>
    );
}

import { ObjectId } from 'bson'
import * as api from './apiActions'
import * as xtypes from 'xtypes'

export async function getAssignments(): Promise<xtypes.db.WithId<xtypes.apps.IAssignmentInfo>[]> {
    const response = await api.httpGet('GET ASSIGNMENTS', api.url('api/chat/assignment'), true);
    return await api.json<xtypes.db.WithId<xtypes.apps.IAssignmentInfo>[]>(response);
}

export async function getScenarios(): Promise<xtypes.db.WithId<xtypes.apps.IScenarioInfo>[]> {
    const response = await api.httpGet('GET SCENARIOS', api.url('api/chat/scenario'), true);
    return await api.json<xtypes.db.WithId<xtypes.apps.IScenarioInfo>[]>(response);
}

export async function getSessions(): Promise<xtypes.db.WithId<xtypes.apps.ISessionInfo>[]> {
    const response = await api.httpGet('GET SESSIONS', api.url('api/chat'), true);
    return await api.json<xtypes.db.WithId<xtypes.apps.ISessionInfo>[]>(response);
}

export async function getSession(sessionId: ObjectId): Promise<xtypes.db.WithId<xtypes.apps.ISessionInfo>> {
    const response = await api.httpGet('GET SESSION', api.url(`api/chat/${sessionId}`), true);
    return await api.json<xtypes.db.WithId<xtypes.apps.ISessionInfo>>(response);
}

export async function newSession(request: xtypes.chat.ICreateChatRequest): Promise<ObjectId> {
    const response = await api.httpPost('NEW SESSION', api.url('api/chat'), request, true);
    return await api.json(response);
}

export async function getMessages(sessionId: ObjectId): Promise<xtypes.apps.ISessionMessage[]> {
    const response = await api.httpGet('GET MESSAGES', api.url(`api/chat/${sessionId}/message`), true);
    return await api.json<xtypes.apps.ISessionMessage[]>(response);
}

export async function addMessage(sessionId: ObjectId, input: string): Promise<xtypes.chat.IChatResponse> {
    const response = await api.httpPost('ADD MESSAGE', api.url(`api/chat/${sessionId}/message`), input, true);
    return await api.json(response);
}

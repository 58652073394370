// $$$ COPIED FROM XLIB
export function empty(): Promise<void> {
    return new Promise((resolve)=>{resolve()});
}

export function fromValue<T>(value: T): Promise<T> {
    return new Promise<T>((resolve)=>{resolve(value)});
}

export function fromEval<T>(callback: () => T): Promise<T> {
    return new Promise<T>((resolve)=>{resolve(callback())});
}

import { ReactNode } from 'react'

export interface ContentListProps {
    children: ReactNode;
    title: string;
}

export default function ContentList(props: ContentListProps) {
    return (
        <>
            <div className='fw-bold border-bottom border-primary-subtle border-2'>{props.title}</div>
            <div className='container p-0 mt-2 mb-2'> {/* SCROLLABLE */}
                {props.children}
            </div>
        </>
    );
};

import { createContext, useContext } from 'react'

export interface IdleContextType {
    isIdle: () => boolean;
}

export const IdleContext = createContext<IdleContextType>(null!);

export function useIdle(): IdleContextType {
    return useContext(IdleContext);
}

import { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import styles from './PasswordChange.module.css'

export interface PasswordChangeProps {
    onChange: (password: string) => void;
}

interface IPasswordValidation {
    password?: string;
    error1?: string;
    error2?: string;
}

const lengthPassword = 8;
const patternPassword = new RegExp('^([a-zA-Z0-9!@$%^&(){}[\\]:;<>,.?/~_+-=|]+)$');

export default function PasswordChange(props: PasswordChangeProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isValidated, setValidated] = useState(false);
    const [validation, setValidation] = useState<IPasswordValidation>({});
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    async function onChange() {
        const validation = validateForm();
        setValidated(true);
        if (validation == null) {
            return;
        }
        await props.onChange(validation.password!);
    }

    function isFormComplete(): boolean {
        return (
            password1.trim().length > 0 &&
            password2.trim().length > 0);
    }

    function validateForm(): IPasswordValidation | undefined {
        let p1 = password1.trim();
        let p2 = password2.trim();

        let v1: string | undefined = undefined;
        let v2: string | undefined = undefined;
        if (p1 !== p2) {
            v2 = 'Passwords do not match';
        }
        if (p1.length < lengthPassword) {
            v1 = "Password too short"
        } else if (p1.match(patternPassword) == null) {
            v1 = "Invalid characters in password"
        }

        const validation: IPasswordValidation = {
            password: p1,
            error1: v1,
            error2: v2,
        };
        setValidation(validation)

        if ((validation.error1 == null) &&
            (validation.error2 == null)) {
            return validation;
        }
    }

    return (
        <>
            <div className='auth-form'>
                <Form.Group className='mt-4 mb-3'>
                    <Form.Label className='fw-bold' htmlFor='login-password'>Password:</Form.Label>
                    <Form.Control
                        id='login-password'
                        ref={inputRef}
                        type='password'
                        isInvalid={isValidated && validation.error1 != null}
                        placeholder='New Password'
                        onChange={(e) => setPassword1(e.currentTarget.value)} />
                    <Form.Control.Feedback type="invalid">
                        {validation.error1}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-4 mb-3'>
                    <Form.Label className='fw-bold' htmlFor='login-confirm'>Confirm:</Form.Label>
                    <Form.Control
                        id='login-confirm'
                        type='password'
                        isInvalid={isValidated && validation.error2 != null}
                        placeholder='Confirm Password'
                        onChange={(e) => setPassword2(e.currentTarget.value)} />
                    <Form.Control.Feedback type="invalid">
                        {validation.error2}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='auth-actions'>
                    <Button
                        variant={isFormComplete() ? 'primary' : 'outline-primary'}
                        tabIndex={0}
                        disabled={!isFormComplete()}
                        onClick={onChange}>
                        Update
                    </Button>
                </Form.Group>
            </div>
            <div className={styles.note}>
                <div className='fw-bold'>Note:</div>
                <ul>
                    <li>Password must be at least 8 characters.</li>
                    <li>Password may contain letters, numbers, and symbols...but not spaces or emojis.</li>
                </ul>
            </div>
        </>);
}

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as api from '../../services/apiDirectory'
import Backdrop from '../../components/general/Backdrop'
import PasswordChange from '../../components/settings/PasswordChange'
import * as AppPaths from '../../appPaths'

export default function UserPassword() {

  const [isProcessing, setProcessing] = useState(false);
  const navigate = useNavigate();

  async function updatePassword(password: string) {
    setProcessing(true);
    api.changePassword(password)
      .then(didSucceed => {
        setProcessing(false);
        if (didSucceed) {
          navigate(AppPaths.profile)
        } else {
          // $$$ FAILURE TODO
        }
      })
  }

  return (
    <>
      <Backdrop text='Updating...' show={isProcessing} />
      <div className='fs-3 fw-bold mb-3'>Change Password</div>
      <div>Complete the requested fields to create a new account for a team member.</div>
      <PasswordChange onChange={updatePassword} />
    </>
  );
}

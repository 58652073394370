import { config } from './config'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import RequireAuth from './auth/RequireAuth'
import { AuthProvider, RequireAuth } from './providers/AuthProvider'
import { IdleProvider } from './providers/IdleProvider'
import * as AppRoutes from './appRoutes'
import * as Layouts from './layout'
import * as Views from './views'

export default function App() {

    return (
        <IdleProvider threshold={config.app.InactivityThreshold}>
            <AuthProvider threshold={config.app.RefreshThreshold}>
                <Router>
                    <Routes>
                        <Route path={AppRoutes.home} element={<Layouts.Main />}>
                            <Route index element={<Views.Info />} />
                            <Route path={AppRoutes.sound} element={<Views.ChatSound />} />
                            {/* AUTH */}
                            <Route path={AppRoutes.signin} element={<Views.AuthSignin />} />
                            <Route path={AppRoutes.activate} element={<Views.AuthActivate />} />
                            {/* PROGRAM */}
                            <Route path={AppRoutes.evalhome} element={<RequireAuth><Layouts.Program /></RequireAuth>}>
                                <Route index element={<Views.Activity />} />
                                <Route path={AppRoutes.chat} element={<Views.Chat />} />
                            </Route>
                            {/* SETTINGS */}
                            <Route path={AppRoutes.settings} element={<RequireAuth><Layouts.Settings /></RequireAuth>}>
                                {/* <Route index element={<Views.UserProfile />} /> */}
                                <Route path={AppRoutes.profile} element={<Views.UserProfile />} />
                                <Route path={AppRoutes.password} element={<Views.UserPassword />} />
                                <Route path={AppRoutes.users} element={<RequireAuth role={'operator'}><Views.Users /></RequireAuth>} />
                                <Route path={AppRoutes.userinvite} element={<RequireAuth role={'operator'}><Views.UserInvite /></RequireAuth>} />
                                <Route path={AppRoutes.teams} element={<RequireAuth role={'operator'}><Views.Teams /></RequireAuth>} />
                                <Route path={AppRoutes.training} element={<RequireAuth role={'operator'}><Views.Training /></RequireAuth>} />
                                <Route path={AppRoutes.teamassign} element={<RequireAuth role={'operator'}><Views.Assignments /></RequireAuth>} />
                                <Route path={AppRoutes.admin} element={<RequireAuth role={'operator'}><Views.ChatAdmin /></RequireAuth>} />
                                {/* <Route path={AppRoutes.teamlist} element={<RequireAuth role={xtypes.auth.Roles.operator}><Views.Team /></RequireAuth>} />
                            <Route path={AppRoutes.teaminvite} element={<RequireAuth role={xtypes.auth.Roles.operator}><Views.TeamInvite /></RequireAuth>} />
                            <Route path={AppRoutes.teamassign} element={<RequireAuth role={xtypes.auth.Roles.operator}><Views.Assign /></RequireAuth>} />
                            <Route path={AppRoutes.admin} element={<RequireAuth role={xtypes.auth.Roles.operator}><Views.ChatAdmin /></RequireAuth>} /> */}
                            </Route>
                            {/* COMPANY */}
                            <Route path={AppRoutes.us} element={<Layouts.Company />}>
                                <Route index element={<Views.About />} />
                                <Route path={AppRoutes.usabout} element={<Views.About />} />
                                <Route path={AppRoutes.uscontact} element={<Views.Contact />} />
                                <Route path={AppRoutes.uscareers} element={<Views.Careers />} />
                            </Route>
                        </Route>
                        {/* LANDING */}
                        <Route path={AppRoutes.home} element={<Layouts.Landing />}>
                            <Route path={AppRoutes.landing1} element={<Views.LandingTry />} />
                        </Route>
                        {/* NOTFOUND */}
                        <Route path='*' element={<Views.NotFound />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </IdleProvider >
    );
}


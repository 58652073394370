import { useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import PDFViewer from './PDFViewer'
import * as AppPaths from '../../appPaths'
import styles from './Footer.module.css'

export interface FooterProps {
    minimal?: boolean;
    onContact?: () => void;
}

export default function Footer(props: FooterProps) {
    const [showPrivacy, setShowPrivacy] = useState(false);
    const navigate = useNavigate();

    function onAbout() {
        navigate(AppPaths.usabout);
    }

    function onCareers() {
        navigate(AppPaths.uscareers);
    }

    function onContact() {
        if (props.onContact) {
            props.onContact();
            return;
        }
        navigate(AppPaths.uscontact);
    }

    return (
        <>
            <div className={styles.container}>
                {filter(<div className={styles.action} onClick={onAbout}>About Facilibus</div>)}
                <div className={styles.action} onClick={onContact}>Contact Us</div>
                <div>© 2024 Facilibus, All Rights Reserved</div>
                <div className={styles.action} onClick={() => setShowPrivacy(true)}>Privacy Policy</div>
                {filter(<div className={styles.action} onClick={onCareers}>Careers</div>)}
            </div>
            <PDFViewer document='/privacy-policy.pdf' show={showPrivacy} onHide={() => setShowPrivacy(false)} />
        </>
    );

    function filter(element: ReactNode): ReactNode {
        return props.minimal ? <></> : element;
    }
}



import { create } from 'zustand'
import { ObjectId } from 'bson'
import IManagedState from './IManagedState'
import * as api from '../services/apiChat'
import * as xtypes from 'xtypes'

export type MessageCallback = (text: string, completed: boolean) => void;

export interface ChatState extends IManagedState {
    sessionId?: ObjectId | undefined;
    session?: xtypes.db.WithId<xtypes.apps.ISessionInfo>;
    history: xtypes.apps.ISessionMessage[];
    newSession: (request: xtypes.chat.ICreateChatRequest) => Promise<void>;
    setSession: (sessionId: ObjectId) => void;
    loadChat: (sessionId: ObjectId) => Promise<xtypes.apps.ISessionMessage[]>;
    addMessage: (ObjectId: ObjectId, text: string, callback: MessageCallback) => void;
}

export const useChatStore = create<ChatState>((set) => ({
    history: [],
    didInit: false,
    error: undefined,
    clearError: () => set({ error: undefined }),
    init: async () => { },
    loadChat: async (sessionId: ObjectId) => {
        try {
            const sessionPromise = api.getSession(sessionId);
            const historyPromise = await api.getMessages(sessionId);
            const [session, history] = await Promise.all([sessionPromise, historyPromise])
            set({ didInit: true, session, history });
            return history;
        } catch (err: any) {
            set({ error: err });
            return [];
        }
    },
    setSession: (sessionId) => {
        set({ didInit: false, sessionId, session: undefined, history: [] });
    },
    newSession: async (request: xtypes.chat.ICreateChatRequest) => {
        try {
            const sessionId = await api.newSession(request);
            set({ didInit: false, sessionId, session: undefined, history: [] });
        } catch (err: any) {
            set({ error: err });
        }
    },
    addMessage: async (sessionId, text, callback) => {
        const input: xtypes.apps.ISessionMessage = {
            _session: sessionId,
            role: 'input',
            content: text,
            whencreated: new Date(),
        }
        set((state) => ({ history: [...state.history, input] }));
        try {
            const response = await api.addMessage(sessionId, text);
            set((state) => ({ history: [...state.history, response.message] }));
            callback(response.message.content, response.completed ?? false);
        } catch (err: any) {
            set({ error: err });
        }
    },
}));
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import './PDFViewer.css'

export interface PDFViewerProps {
    document: string;
    show: boolean;
    onHide: () => void;
}

export default function PDFViewer(props: PDFViewerProps) {
    return (
        <Modal dialogClassName='privacy-container' show={props.show} fullscreencentered backdrop autoFocus onHide={props.onHide}>
            <Modal.Header closeButton className='privacy-header'>
                <Modal.Title className='privacy-title'>Facilibus Privacy Policy</Modal.Title>
            </Modal.Header>
            <div className='privacy-body'>
                <iframe title='viewer' className='privacy-frame' src={props.document} />
            </div>
            <Modal.Footer>
                <Button variant='primary' onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

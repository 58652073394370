import { FieldValues, useFormContext } from 'react-hook-form'
import { ObjectId } from 'bson'
import renderIf from '../../lib/renderIf'
import * as equal from '../../lib/equal'
import * as apiApps from '../../services/apiApps'
import Backdrop from '../../components/general/Backdrop'
import DynamicForm from '../form/DynamicForm'
import DynamicFormField from '../form/DynamicFormField'
import * as dynamic from '../form/DynamicTypes'
import { OverlayTrigger } from 'react-bootstrap'
import { Popover } from 'react-bootstrap'
import * as xtypes from 'xtypes'

const ExerciseNameField = 'exercisename';
const ExerciseDescriptionField = 'exercisedescription';

interface CreateExerciseProps {
    application: xtypes.apps.IApplicationInfo | undefined;
    exercise?: xtypes.apps.IExerciseInfo | undefined;
    fields: dynamic.FormField[];
    template?: xtypes.docs.JSONNode;
    onSubmit: dynamic.FormSubmitHandler;
}

interface CreateExerciseFieldsProps {
    name?: string;
    description?: string;
}

export function Form(props: CreateExerciseProps) {

    console.log(`EXERCISE FORM RENDER: ${props.fields.length}`);

    return (
        <>
            {renderIf(props.fields?.length > 0,
                <DynamicForm
                    onSubmit={props.onSubmit}
                    defaultValues={dynamic.getFormDefaults(props.fields, props.template)}>
                    <>
                        <Fields name={props.exercise?.name} description={props.exercise?.description} />
                        {props.fields.map((field, index) =>
                            <div key={`field${index}`}>
                                <DynamicFormField
                                    field={field}
                                    default={dynamic.getFieldDefault(field, props.template)}
                                />
                            </div>
                        )}
                    </>
                </DynamicForm>,
                <Backdrop text='Loading...' />
            )}
        </>);
}

export default function Fields(props: CreateExerciseFieldsProps) {

    const { register } = useFormContext();

    return (
        <>
            <OverlayTrigger
                placement="left-start"
                delay={{ show: 500, hide: 100 }}
                overlay={<Popover body={true}>The name of the exercise</Popover>}
            >
                {({ ref, ...triggerHandler }) => (
                    <div className='mb-3' {...triggerHandler}>

                        <label className='form-label fw-bold' htmlFor={ExerciseNameField} ref={ref}>Exercise Name:</label>
                        <input
                            id={ExerciseNameField}
                            className='form-control'
                            type='text'
                            tabIndex={0}
                            defaultValue={props.name}
                            {...register(ExerciseNameField, { required: true })}
                        />
                    </div>
                )}
            </OverlayTrigger>
            <OverlayTrigger
                placement="left-start"
                delay={{ show: 500, hide: 100 }}
                overlay={<Popover body={true}>The exercise description</Popover>}
            >
                {({ ref, ...triggerHandler }) => (
                    <div className='mb-3' {...triggerHandler}>

                        <label className='form-label fw-bold' htmlFor={ExerciseDescriptionField} ref={ref}>Exercise Description:</label>
                        <textarea
                            id={ExerciseDescriptionField}
                            className='form-control'
                            tabIndex={0}
                            defaultValue={props.description}
                            {...register(ExerciseDescriptionField, { required: true })}
                        />
                    </div>
                )}
            </OverlayTrigger>
        </>);
}

export async function CreateExercise(
    data: FieldValues,
    applicationId: ObjectId,
): Promise<void> {
    // console.log('EXERCISE NEW RAW:');
    // console.log(data);
    const name = data[ExerciseNameField] as string;
    const description = data[ExerciseDescriptionField] as string;
    delete data[ExerciseNameField];
    delete data[ExerciseDescriptionField];
    // console.log('EXERCISE NEW CLEAN:');
    // console.log(data);
    const request = {
        applicationId,
        name,
        description,
        template: data
    } as xtypes.docs.IExerciseTemplateRequest;
    await apiApps.createExercise(request);
}

export async function UpdateExercise(
    data: FieldValues,
    original: xtypes.docs.JSONNode,
    exercise: xtypes.db.WithId<xtypes.apps.IExerciseInfo>): Promise<boolean> {
    // console.log('EXERCISE ORIGINAL:');
    // console.log(template ?? '-');
    // console.log('EXERCISE UPDATE RAW:');
    // console.log(data);
    const name = data[ExerciseNameField] as string;
    const description = data[ExerciseDescriptionField] as string;
    delete data[ExerciseNameField];
    delete data[ExerciseDescriptionField];
    // console.log('EXERCISE UPDATE CLEAN:');
    // console.log(data);

    const promises: Promise<any>[] = [];
    // upload template, if changed
    if (!equal.sameObject(data, original)) {
        console.log('SUBMIT: TEMPLATE UPDATED');
        const templatePromise = apiApps.updateExerciseTemplate(exercise._id, data);
        promises.push(templatePromise);
    }

    // update exercise, if changed
    const delta: Partial<xtypes.apps.IExercise> = {};
    if (!equal.sameValue(name, exercise?.name)) {
        delta.name = name;
    }
    if (!equal.sameValue(description, exercise?.description)) {
        delta.description = description;
    }
    if (Object.keys(delta).length > 0) {
        console.log('SUBMIT: EXERCISE UPDATED');
        const exercisePromise = apiApps.updateExercise(exercise._id, delta);
        promises.push(exercisePromise);
    }

    await Promise.all([promises]);

    return delta.name != null; // $$$ DESCRIPTION NOT REFRESHED
}


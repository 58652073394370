import log from 'loglevel'

interface AppConfig {
    PortAPI: string;
    PortTTS: string;
    PortSTT: string;
    InactivityCountdown: number;
    InactivityThreshold: number;
    RefreshThreshold: number;
}

const defaultConfig: AppConfig = {
    PortAPI: '8080',
    PortTTS: '8081',
    PortSTT: '8082',
    InactivityCountdown: 1000 * 30, // 30 seconds
    InactivityThreshold: 1000 * 60 * 3, // 3 minutes
    RefreshThreshold: 1000 * 60 * 4, // 4 minutes remaining
};

const configUrl = "/config.json";

class ConfigLoader {
    public app: AppConfig = defaultConfig;

    public async load(): Promise<void> {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            }
        };
        const response = await fetch(configUrl, options);
        if (response.status !== 200) {
            log.error(`Failed to load config: ${response.statusText}`);
        }
        this.app = await response.json();

        log.debug(this.app);
    }
}

export const config = new ConfigLoader();

import { create } from 'zustand'
import IManagedState from './IManagedState'
import * as array from '../lib/array'
import * as api from '../services/apiDirectory'
import * as xtypes from 'xtypes'

export interface AdminDirectoryState extends IManagedState {
    roles: xtypes.directory.IRole[];
    users: xtypes.directory.IPrincipal[];
    index: Record<string, xtypes.directory.IPrincipal>;
    fetchUsers: () => Promise<void>;
}

export const useAdminDirectoryStore = create<AdminDirectoryState>((set) => ({
    didInit: false,
    roles: [],
    users: [],
    index: {},
    deltas: {},
    error: undefined,
    clearError: () => set({ error: undefined }),
    init: async () => {
        try {
            const rolesPromise = api.getRoles();
            const usersPromise = api.getUsers();
            const [roles, users] = await Promise.all([rolesPromise, usersPromise])
            set({ didInit: true, roles, users, index: array.dictionary(users, (user) => user.name) });
        } catch (err: any) {
            set({ error: err });
        }
    },
    fetchUsers: async () => {
        const users = await api.getUsers();
        set({ users, index: array.dictionary(users, (user) => user.name) });
    },
}));

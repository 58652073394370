import React, { createContext, useContext, useEffect, useState } from 'react'
import classnames from 'classnames'
import renderIf from '../../lib/renderIf';
import styles from './ContentBox.module.css'
import { ReactComponent as CollapseIcon } from '../../assets/collapse.svg'
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg'

interface ContextBoxContextType {
    expand: boolean;
    setExpand: (expand: boolean) => void;
}

const ContextBoxContext = createContext<ContextBoxContextType>(null!);

interface ContentBoxProps {
    children: React.ReactNode;
    disabled?: boolean;
    expanded?: boolean;
}

interface ContentHeaderProps {
    children: React.ReactNode;
    flat?: boolean;
}

interface ContentBodyProps {
    children: React.ReactNode;
}

const ContentBox = (props: ContentBoxProps) => {
    const [expand, setExpand] = useState(false);
    useEffect(() => {
        setExpand(props.expanded ?? false);
    }, [props.expanded]);
    return (
        <ContextBoxContext.Provider value={{ expand, setExpand }}>
            <div className={classnames(styles.box, { [styles.disabled]: props.disabled })}>
                {props.children}
            </div>
        </ContextBoxContext.Provider>
    );
}

const Header = (props: ContentHeaderProps) => {
    const context = useContext(ContextBoxContext);
    return (
        <div
            className={classnames(styles.header, { [styles.flat]: props.flat })}>
            <div>{props.children}</div>
            {renderIf(props.flat !== true,
                <div className={styles.action} onClick={() => context.setExpand(!context.expand)}>{renderIf(context.expand, <CollapseIcon />, <ExpandIcon />)}</div>
            )}
        </div>
    );
}

const Body = (props: ContentBodyProps) => {
    const context = useContext(ContextBoxContext);
    return (
        <>
            {renderIf(
                context.expand,
                <div className={classnames(styles.body)}>{props.children}</div>
            )}
        </>
    );
}

ContentBox.Header = Header;
ContentBox.Body = Body;

export default ContentBox;
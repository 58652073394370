// general
export const home = '/';
export const signin = '/signin';
export const activate = '/activate';

// settings: user
export const profile = '/settings/profile';
export const password = '/settings/password';
// settings: team
export const users = '/settings/users';
export const userinvite = '/settings/invite';
export const teams = '/settings/teams';
export const training = '/settings/training';
export const teamassign = '/settings/assignments';
export const admin = '/settings/admin';

// company info
export const usabout = '/us/about';
export const uscareers = '/us/careers';
export const uscontact = '/us/contact';

// learning / training
export const evalhome = '/program'
export const chat = '/program/chat';
export const sound = '/sound';

// landing
export const landing1 = '/try';

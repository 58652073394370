import { Outlet } from 'react-router-dom'
// import { NavLink, Outlet } from 'react-router-dom'
// import { Container } from 'react-bootstrap'
// import * as AppPaths from '../appPaths'
// import '../styles/menu.css'

export default function Program() {
    return (
        <>
            {/* <div className='nav-menu'>
                <Container>
                    <NavLink to={AppPaths.evalhome} end>Activity</NavLink>
                    <NavLink to={AppPaths.chat}>Chat</NavLink>
                    <NavLink to={AppPaths.admin}>Admin</NavLink>
                    <NavLink to={AppPaths.sound}>Sound</NavLink>
                </Container>
            </div> */}
            <Outlet />
        </>
    );
}

import log from 'loglevel';
import * as api from './apiActions'
import * as xtypes from 'xtypes'

export async function getRoles(): Promise<xtypes.directory.IRole[]> {
    try {
        const response = await api.httpGet('GET ROLES', api.url('api/id/role'), true);
        return await api.json<xtypes.directory.IRole[]>(response);
    } catch (err) {
        log.error(err);
        return [];
    }
}

export async function getDefaultRole(): Promise<string | undefined> {
    try {
        const response = await api.httpGet('GET DEFAULT ROLE', api.url('api/id/role/default'), true);
        return await response.text();
    } catch (err) {
        log.error(err);
    }
}

export async function getUsers(): Promise<xtypes.directory.IPrincipal[]> {
    try {
        const response = await api.httpGet('GET USERS', api.url('api/id/user'), true);
        return await api.json<xtypes.directory.IPrincipal[]>(response);
    } catch (err) {
        log.error(err);
        return [];
    }
}

export async function getSelf(): Promise<xtypes.directory.IPrincipal | undefined> {
    try {
        const response = await api.httpGet('GET SELF', api.url('api/id/auth'), true);
        return await api.json<xtypes.directory.IPrincipal>(response);
    } catch (err) {
        log.error(err);
    }
}
export async function inviteUser(email: string, name: string, display: string): Promise<xtypes.auth.IInviteRespone | undefined> {
    try {
        const invite: xtypes.auth.IInviteRequest = {
            name,
            email,
            display
        }
        const response = await api.httpPost('INVITE USER', api.url('api/id/user'), invite, true);
        return await api.json<xtypes.auth.IInviteRespone>(response);
    } catch (err) {
        log.error(err);
    }
}

export async function validateInvite(ticket: string): Promise<boolean> {
    try {
        await api.httpGet('VERIFY INVITE', api.url(`api/id/user/invite/${ticket}`), true);
        return true;
    } catch (err) {
        log.error(err);
        return false;
    }
}

export async function activateInvite(ticket: string, password: string): Promise<boolean> {
    try {
        const invite: xtypes.auth.IActivateRequest = {
            ticket,
            password
        }
        await api.httpPost('ACTIVATE INVITE', api.url('api/id/user/invite'), invite, true);
        return true;
    } catch (err) {
        log.error(err);
        return false;
    }
}

export async function changePassword(password: string): Promise<boolean> {
    try {
        await api.httpPatch('UPDATE PASSWORD', api.url(`api/id/auth/${password}`), {}, true);
        return true;
    } catch (err) {
        log.error(err);
        return false;
    }
}
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

export interface ConfirmProps {
    show: boolean,
    title: string,
    content: string | JSX.Element,
    size?: 'sm' | 'lg' | 'xl',
    onChoice: (confirm: boolean) => void,
}

export default function ConfirmModal(props: ConfirmProps) {
    return (
        <form>
            <Modal show={props.show} size={props.size} centered backdrop autoFocus onHide={() => props.onChoice(false)}>
                <Modal.Header>
                    <Modal.Title className='text-primary fw-bold'>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.content}
                </Modal.Body>
                <Modal.Footer as='div'>
                    <Button variant='danger' onClick={() => props.onChoice(false)}>
                        Cancel
                    </Button>
                    <Button type='submit' variant='success' onClick={() => props.onChoice(true)}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </form>);
}